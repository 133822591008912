import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addMessage } from 'store/actions/messages';
import { Form, initialize, reduxForm, SubmissionError } from 'redux-form';
import { setSingleData, updateCurrentState, updateLoadingState } from 'store/actions/single';
import { URLBuild as handleCiviURLBuild } from 'helpers/CiviCRM';
import './styles.css';
import { Box, Button, Heading, Layer } from 'grommet';
import { Checkmark, Close } from 'grommet-icons';
import Demographics from 'helpers/Fieldsets/Demographics';
import * as moment from 'moment-timezone';

// settings on what to ask for
const initialState = {
  somethingMissing: false,
  showEmail: null,
  showPhone: null,
  showPronoun: null,
  showDOB: null,
  showVeteran: null,
  showRace: null,
  showDisabled: null,
  showHIV: false, // this is in the intake not demographics
  showOrientation: null,
  showGender: null,
  showTrans: null,
  showHomeless: null,
  showHouseholdIncome: null,
  showHouseholdSize: null,
  showPhotoPermission: null,
  showLiabilityRelease: null,
  showCommunicationAgreements: null,
};

class DataEnrichmentDemographics extends Component {

  constructor(props) {
    super(props);
    this.state = {...initialState};
  }

  componentDidMount() {
    /**
     * reinitialize forms on load if we already know the data, otherwise load data from CiviCRM
     */    
    const currentState = this.calculateCurrentListState();
    const { state } = this.props;
    if (state === currentState) {
      const { data } = this.props;
      this.props.initializeDataEnrichment(data);
    } else {
      this.loadData();
    }
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    this.loadData();
  }
  
  calculateCurrentListState = () => {
    const listState = [
      this.props.contactId,
    ].join('|');
    return listState;
  }

  loadData = () => {
    
    const currentState = this.calculateCurrentListState();
    const { state, loadingState, toggle } = this.props;
    // console.log({ currentState, state, loadingState })
    if (
      currentState === state ||
      currentState === loadingState
    ) return;

    const { 
      contactId,
      handleUpdateLoadingState,
      handleUpdateCurrentState,
      handleSetSingleData,
      handleMessage,
    } = this.props;

    if (typeof state === 'undefined' && typeof loadingState === 'undefined')
      return;
    // return;

    handleUpdateLoadingState(currentState);

    const url = handleCiviURLBuild('Contact','get',{
      sequential: 1,
      id: contactId,
      "api.Email.get": { sequential: 1 }, // email
      "api.Phone.get": { sequential: 1 }, // phone
      return: [
        "custom_280", // pronoun
        "birth_date", // dob
        "custom_115", // is veteran
        "custom_447", // veteran branch
        "custom_1212", // race
        "custom_1213", // disabled
        "custom_234", // hiv
        "custom_113", // orientation
        "gender_id", // gender
        "custom_1215", // trans
        "custom_1214", // homeless
        "custom_1217", // household income
        "custom_1216", // household size
        "custom_1225", // photo permission
        "custom_1306", // liability release
        "custom_1474", // communication agreements
      ],
    });
    
    return fetch(url, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
    .then(response => response.json())
    .then(json => {
      if (json.is_error)
        throw new Error(json.error_message);
      if (json.values.length!==1)
        throw new Error('We ran into an issue.')

      handleUpdateCurrentState(currentState);

      const account = { contact: {...json.values[0]} };

      // update photo permission, liability release, and communication agreements to boolean for checkbox usage
      account.contact.custom_1225 = account.contact.custom_1225==='1';
      account.contact.custom_1306 = account.contact.custom_1306==='1';
      account.contact.custom_1474 = account.contact.custom_1474==='1';

      handleSetSingleData(account);
      this.props.initializeDataEnrichment(account);

      return account;
    })
    .then(account => {

      // const { contactId } = this.props;

      // calculate which weho items needs displayed
      let {
        showEmail,
        showPhone,
        showPronoun,
        showDOB,
        showVeteran,
        showRace,
        showDisabled,
        showHIV,
        showOrientation,
        showGender,
        showTrans,
        showHomeless,
        showHouseholdIncome,
        showHouseholdSize,
        showPhotoPermission,
        showLiabilityRelease,
        showCommunicationAgreements,
      } = this.state;

      const { contact } = account;
      let somethingMissing = false;
      // const updateDate = moment().tz('America/Los_Angeles').subtract(1, 'year'); // unfortunately nothing is dated

      // email
      if (showEmail===null) { // not already set to show
        const missing = contact['api.Email.get'].count===0;
        somethingMissing = somethingMissing || missing;
        showEmail = missing;
      }
      /// phone
      if (showPhone===null) {
        const missing = contact['api.Phone.get'].count===0;
        somethingMissing = somethingMissing || missing;
        showPhone = missing;
      }
      // pronoun
      if (showPronoun===null) {
        const missing = contact.custom_280==='';
        somethingMissing = somethingMissing || missing;
        showPronoun = missing;
      }
      // dob
      if (showDOB===null) {
        const missing = contact.birth_date==='';
        somethingMissing = somethingMissing || missing;
        showDOB = missing;
      }
      // is veteran // branch
      if (showVeteran===null) {
        const missing = contact.custom_115==='' || (contact.custom_115==='1' && contact.custom_447==='');
        somethingMissing = somethingMissing || missing;
        showVeteran = missing;
      }
      // race
      if (showRace===null) {
        const missing = contact.custom_1212==='';
        somethingMissing = somethingMissing || missing;
        showRace = missing;
      }
      // disabled
      if (showDisabled===null) {
        const missing = contact.custom_1213==='';
        somethingMissing = somethingMissing || missing;
        showDisabled = missing;
      }
      // hiv
      if (showHIV===null) {
        const missing = contact.custom_234==='';
        somethingMissing = somethingMissing || missing;
        showHIV = missing;
      }
       // orientation
       if (showOrientation===null) {
        const missing = contact.custom_113==='';
        somethingMissing = somethingMissing || missing;
        showOrientation = missing;
      }
       // gender
       if (showGender===null) {
        const missing = contact.gender_id==='';
        somethingMissing = somethingMissing || missing;
        showGender = missing;
      }
       // trans
       if (showTrans===null) {
        const missing = contact.custom_1215==='';
        somethingMissing = somethingMissing || missing;
        showTrans = missing;
      }
       // homeless
       if (showHomeless===null) {
        const missing = contact.custom_1214==='';
        somethingMissing = somethingMissing || missing;
        showHomeless = missing;
      }
       // household income
       if (showHouseholdIncome===null) {
        const missing = contact.custom_1217==='';
        somethingMissing = somethingMissing || missing;
        showHouseholdIncome = missing;
      }
       // household size
       if (showHouseholdSize===null) {
        const missing = contact.custom_1216==='';
        somethingMissing = somethingMissing || missing;
        showHouseholdSize = missing;
      }
       // photo permission
       if (showPhotoPermission===null) {
        const missing = !contact.custom_1225; // boolean
        somethingMissing = somethingMissing || missing;
        showPhotoPermission = missing;
      }
      // liability release
      if (showLiabilityRelease===null) {
        const missing = !contact.custom_1306; // boolean
        somethingMissing = somethingMissing || missing;
        showLiabilityRelease = missing;
      }
      // communication agreements
      if (showCommunicationAgreements===null) {
        const missing = !contact.custom_1474; // boolean
        somethingMissing = somethingMissing || missing;
        showCommunicationAgreements = missing;
      }
      // display this dialog if necessary
      const showAtLeastOneField =
        /**
         * Don't show if exclusively missing email or phone because the person likely does not have them
         * The record is pretty complete otherwise
         */
        // showEmail ||
        // showPhone ||
        showPronoun ||
        showDOB ||
        showVeteran ||
        showRace ||
        showDisabled ||
        showHIV ||
        showOrientation ||
        showGender ||
        showTrans ||
        showHomeless ||
        showHouseholdIncome ||
        showHouseholdSize ||
        showPhotoPermission ||
        showLiabilityRelease ||
        showCommunicationAgreements;
      this.setState({
        somethingMissing,
        showEmail,
        showPhone,
        showPronoun,
        showDOB,
        showVeteran,
        showRace,
        showDisabled,
        showHIV,
        showOrientation,
        showGender,
        showTrans,
        showHomeless,
        showHouseholdIncome,
        showHouseholdSize,
        showPhotoPermission,
        showLiabilityRelease,
        showCommunicationAgreements,
      });
      toggle(showAtLeastOneField);
    })
    .then(account => handleUpdateLoadingState(''))
    .catch((e) => {
      handleUpdateCurrentState(currentState);
      handleMessage(e.message, 'error');
      handleUpdateLoadingState('');
    });
  }

  submitForm = values => {

    const { handleMessage, handleUpdateCurrentState } = this.props;
    
    const contact = {...values.contact};

    /**
     * Validate
     */

    const contactErrors = {};

    // Profile
    
    /**
     * West Hollywood conditional validation
     */

    const {
      showEmail,
      showPhone,
      showPronoun,
      showDOB,
      showVeteran,
      showRace,
      showDisabled,
      showHIV,
      showOrientation,
      showGender,
      showTrans,
      showHomeless,
      showHouseholdIncome,
      showHouseholdSize,
      showPhotoPermission,
      showLiabilityRelease,
      showCommunicationAgreements,
    } = this.state;

    // some people dont have email dont make required and input[type="email"] should take care of email validation
    if (showEmail) {
      // if (!contact.email) {
      //   contactErrors.email = 'Required';
      // } else if (contact.email.indexOf('@')===-1) {
      //   contactErrors.email = 'Invalid email';
      // }
      if (contact.email && contact.email.indexOf('@')===-1) {
        contactErrors.email = 'Invalid email';
      }
    }

    // some people dont have phone dont make required and input[type="tel"] should take care of phone # validation
    if (showPhone) {
      // if (!contact.phone) {
      //   contactErrors.phone = 'Required';
      // } else if (!contact.phone_type_id) {
      //   contactErrors.phone_type_id = 'Required';
      // }
      if (contact.phone && !contact.phone_type_id) {
        contactErrors.phone_type_id = 'Required';
      }
    }

    if (showPronoun) {
      if (!contact.custom_280)
        contactErrors.custom_280 = 'Required';
    }

    if (showDOB) {
      if (!contact.birth_date) {
        contactErrors.birth_date = 'Required';
      } else {
        const birth_date_local = moment(contact.birth_date); // consume in in local timezone
        const birth_date = moment.tz(birth_date_local.format('YYYY-MM-DD'), 'YYYY-MM-DD', 'America/Los_Angeles'); // flip to la timezone to prevent accidentally switching days
        const fifty_years_ago = moment().tz("America/Los_Angeles").subtract(50, 'year').startOf('day');
        // console.log(birth_date.toISOString(), fifty_years_ago.toISOString())
        if (birth_date.isSameOrAfter(fifty_years_ago))
          contactErrors.birth_date = "Thank you for your interest in Senior Services. Participants must be age 50 and older to enroll in our program. Please call us at 323-860-5830 if you have any questions about our program and age requirements.";
      }
    }

    if (showVeteran) {
      if (!contact.custom_115) {
        contactErrors.custom_115 = 'Required';
      } else if (contact.custom_115==='1' && !contact.custom_447) {
        contactErrors.custom_447 = 'Required';
      }
    }

    if (showRace) {
      if (!contact.custom_1212 || (Array.isArray(contact.custom_1212) && contact.custom_1212.length===0))
        contactErrors.custom_1212 = 'Required';
    }

    if (showDisabled) {
      if (!contact.custom_1213)
        contactErrors.custom_1213 = 'Required';
    }

    if (showHIV) {
      if (!contact.custom_234)
        contactErrors.custom_234 = 'Required';
    }

    if (showOrientation) {
      if (!contact.custom_113)
        contactErrors.custom_113 = 'Required';
    }

    if (showGender) {
      if (!contact.gender_id)
        contactErrors.gender_id = 'Required'
    }

    if (showTrans) {
      if (!contact.custom_1215)
        contactErrors.custom_1215 = 'Required';
    }

    if (showHomeless) {
      if (!contact.custom_1214)
        contactErrors.custom_1214 = 'Required';
    }

    if (showHouseholdIncome) {
      if (!contact.custom_1217) {
        contactErrors.custom_1217 = 'Required';
      } else if (!contact.custom_1217.match(/^\d+$/)) {
        contactErrors.custom_1217 = 'Please a number';
      }
    }

    if (showHouseholdSize) {
      if (!contact.custom_1216) {
        contactErrors.custom_1216 = 'Required';
      } else if (!contact.custom_1216.match(/^\d+$/)) {
        contactErrors.custom_1216 = 'Please a number';
      } else {
        const householdSize = parseInt(contact.custom_1216);
        if (householdSize<1) {
          contactErrors.custom_1216 = 'Please a number 1 or greater';
        }
      }
    }

    if (showPhotoPermission) {
      if (!contact.custom_1225)
        contactErrors.custom_1225 = 'Required';
    }

    if (showLiabilityRelease) {
      if (!contact.custom_1306)
        contactErrors.custom_1306 = 'Required';
    }

    if (showCommunicationAgreements) {
      if (!contact.custom_1474)
        contactErrors.custom_1474 = 'Required';
    }

    /**
     * Aggrigate errors
     */
    // console.log(contactErrors)
    const contactErrorsKyes = Object.keys(contactErrors);
    if (contactErrorsKyes.length>0) {
      handleMessage('Please fill out all required information.', 'error');
      throw new SubmissionError({
        contact: contactErrors,
      });
      // throw new SubmissionError({
      //   _error: errorKeys.map(key => errors[key]).join(', '),
      // });
    }

    /**
     * Process some fields into the format we need for saving
     */

    
    if (showEmail) {
      if (contact.email)
        contact['api.Email.create'] ={
          email: contact.email,
        };
    }
    if (showPhone) {
      if (contact.phone) 
        contact['api.Phone.create'] = {
          phone: contact.phone,
          phone_type_id: contact.phone_type_ids,
        };
    }
    if (showDOB) {
      const birth_date_local = moment(contact.birth_date); // consume in in local timezone
      const birth_date = moment.tz(birth_date_local.format('YYYY-MM-DD'), 'YYYY-MM-DD', 'America/Los_Angeles'); // flip to la timezone to prevent accidentally switching days
      contact.birth_date = birth_date.format('YYYY-MM-DD');
    }
    if (showPhotoPermission) {
      contact.custom_1225 = contact.custom_1225 ? '1' : '2'; // not using boolean type
    }
    if (showLiabilityRelease) {
      contact.custom_1306 = contact.custom_1306 ? '1' : '0';
    }
    if (showCommunicationAgreements) {
      contact.custom_1474 = contact.custom_1474 ? '1' : '0';
    }

    /**
     * Add in updated times -- no update time tracking
     */

    // const now = moment().tz('America/Los_Angeles');
    // const format = 'YYYY-MM-DD HH:mm:ss';

    // if (showLivesWeHo)
    //   contact.custom_1364 = now.format(format);

    /**
     * Save
     */

    const formData = new FormData();
    formData.append('json', JSON.stringify(contact));
  
    const url = handleCiviURLBuild('Contact', 'create');

    return fetch(url, {
      method: "POST",
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
      body: formData,
    })
    .then(response => response.json())
    .then(json => {
      // console.log(json);
      if (json.is_error) {
       throw new Error(json.error_message);
      }
      handleMessage('Account information saved', 'success');
      // reset what we are showing
      this.setState(initialState);
      handleUpdateCurrentState(''); // refresh this page
      return json;
    })
    .catch(error => {
      handleMessage(`There was an issue saving your account information (${error.message})`, 'error');
      return false;
    });

  }

  render() {

    const { open, toggle } = this.props;

    if (!open)
      return null;

    const { somethingMissing } = this.state;

    const { handleSubmit, pristine, submitting } = this.props;

    const {
      showEmail,
      showPhone,
      showPronoun,
      showDOB,
      showVeteran,
      showRace,
      showDisabled,
      showHIV,
      showOrientation,
      showGender,
      showTrans,
      showHomeless,
      showHouseholdIncome,
      showHouseholdSize,
      showPhotoPermission,
      showLiabilityRelease,
      showCommunicationAgreements,
    } = this.state;

    return (
      <Layer 
        className="DataEnrichment DataEnrichmentDemographics"
        full={true}
        margin="large"
        model={true}
        onClickOutside={() => toggle(false)}
        onEsc={() => toggle(false)}
        plain={false}
        responsive={true}
      >
        <Box fill>
          <Box
            direction='row'
            align='center'
            justify='between'
            background="light-2"
            pad={{ left: 'medium', right: 'small', vertical: 'small' }}
            elevation='medium'
            style={{ zIndex: '1' }}
          >
            <Box flex>
              <Heading level="2" style={{ margin: 0 }}>
                We need to know a little more about you
              </Heading>
            </Box>
            <Box>
              <Button icon={<Close />} label="Close" onClick={() => toggle(false)} />
            </Box>
          </Box>
          <Box fill style={{ padding: '24px', overflowY: 'auto' }}>
            <Form onSubmit={handleSubmit(this.submitForm)}>
              <p><em>
                We work with many funding sources to provide free and low cost 
                services. As part of our  agreement with the funding sources, we 
                must collect some basic demographic information. All responses 
                are confidential.
              </em></p>
              <Demographics
                {...this.props}
                show={{
                  email: showEmail===true,
                  phone: showPhone===true,
                  pronoun: showPronoun===true,
                  dob: showDOB===true,
                  veteran: showVeteran===true,
                  race: showRace===true,
                  disabled: showDisabled===true,
                  hiv: showHIV===true,
                  orientation: showOrientation===true,
                  gender: showGender===true,
                  trans: showTrans===true,
                  homeless: showHomeless===true,
                  householdIncome: showHouseholdIncome===true,
                  householdSize: showHouseholdSize===true,
                  photoPermission: showPhotoPermission===true,
                  liabilityRelease: showLiabilityRelease===true,
                  communicationAgreements: showCommunicationAgreements===true,
                }}
              />
              <div className="submit-buttons">
                <Button
                  icon={<Checkmark />}
                  label="Update Account"
                  type="submit"
                  primary={true}
                  disabled={(somethingMissing && pristine) || submitting}
                />
              </div>
            </Form>
          </Box>
        </Box>
      </Layer>
    );
  }
}

const singleName = 'dataenrichment_demographics';
const formName = 'dataenrichment_demographics';

const mapStateToProps = (state) => {

  const { auth: { contactId }, form } = state;
  
  const single = state.single[singleName];

  const formLoaded = formName in form && 'values' in form[formName];
  const formData = formLoaded && form[formName].values;

  return {
    contactId,
    ...single,
    formLoaded,
    formData,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    handleMessage: (message, variant=null, undo=null) => dispatch(addMessage(message, variant, undo)),

    handleUpdateLoadingState: state => dispatch(updateLoadingState(singleName, state)),
    handleUpdateCurrentState: state => dispatch(updateCurrentState(singleName, state)),
    handleSetSingleData: data => dispatch(setSingleData(singleName, data)),

    initializeDataEnrichment: data => dispatch(initialize(formName, data)),
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: formName,
  })(DataEnrichmentDemographics)
);