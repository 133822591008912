import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Box, Button, Heading, Layer } from 'grommet';

class ConfirmClose extends Component {

  render() {

    const { open, toggle } = this.props;
    // console.log(toggle);

    if (!open)
      return null;

    return (
      <Layer
        className="ConfirmClose"
        // full={true}
        margin="large"
        model={true}
        onClickOutside={() => toggle(false)}
        onEsc={() => toggle(false)}
        plain={false}
        responsive={true}
      >
        <Box fill>
          <Box
            direction='row'
            align='center'
            justify='between'
            background="light-2"
            pad={{ left: 'medium', right: 'small', vertical: 'small' }}
            elevation='medium'
            style={{ zIndex: '1' }}
          >
            <Box flex>
              <Heading level="2" style={{ margin: 0 }}>Are you sure?</Heading>
            </Box>
          </Box>
          <Box fill style={{ overflowY: 'auto' }}>
            <Box pad="medium" margin="medium">Are you sure you would like to close this window without submitting your case management request?</Box>
          </Box>
          <div>
            <Box justify="center" direction="row" >
              <Box>
                <Button
                  margin="small"
                  label="No"
                  onClick={() => toggle(false)}
                />
              </Box>
              <Box>
                <Button
                  margin="small"
                  label="Yes"
                  primary={true}
                  onClick={() => toggle(false, true)}
                />
              </Box>
            </Box>
          </div>
        </Box>
      </Layer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
  };
}

const mapDispatchToProps = dispatch => {
  return {
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmClose);