import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { addMessage } from 'store/actions/messages';
import { Field } from 'redux-form';
import { Box } from 'grommet';
import { timeDisplay } from 'helpers/CiviCRM';
import { renderCheckBox } from 'helpers/FormFields';

class EventDisplay extends Component {

  rowClick = (participation, name, currentValue) => this.props.change(name, !currentValue)

  render() {

    const { participation } = this.props;

    const name = `checkins[checkin_${participation.id}]`;
    let currentValue = false;

    return (
      <Box
        className="EventDisplay"
        pad="small"
        border={{ side: "all", size: "medium" }}
        direction="row"
        onClick={e => this.rowClick(participation, name, currentValue)}
      >
        <Box
          flex={false}
          justify="center"
        >
          <Field
            // label={participation.event_title}
            name={name} 
            component={props =>{
              currentValue = props.input.value;
              return renderCheckBox(props);
            }}
          />
        </Box>
        <Box className="info" flex justify="center">
          <div>
            {participation.event_title}
            <br />
            <small>
              {participation.event_location || participation.room_location ? 
                <span style={{ marginRight: 12 }}>
                  {participation.event_location}
                  {participation.event_location && participation.room_location && ' / '}
                  {participation.room_location}
                </span> 
              : '-- ask for location --'}
              <br />
              {timeDisplay(participation)}
            </small>
          </div>
        </Box>
      </Box>
    );
  }
}

const formName = 'checkin';

const mapStateToProps = (state) => {
  const { mobileMode } = state.display;
  return {
    mobileMode,
  };
}

const mapDispatchToProps = dispatch => {

  return {
    handleMessage: (message, variant=null, logout=false) => dispatch(addMessage(message, variant, logout)),
    change: (field, value) => dispatch(change(formName, field, value)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventDisplay);