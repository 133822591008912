const housingNavigationServices = [
  {
    label: 'I am currently unhoused',
    value: '1',
  },
  {
    label: 'I am housed, but would like assistance finding and applying for affordable housing',
    value: '2',
  },
  {
    label: 'I need assistance with finding assisted care settings for myself or a loved one (nursing care, skilled nursing facilities, board and care, assisted living, etc)',
    value: '3',
  }
];

export default housingNavigationServices;