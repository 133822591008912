import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  updateCurrentPage, 
  updatePageSize, 
  updateSorting, 
  updateSearchValue, 
  updateCurrentListState,
  updateLoadingListState,
  setListData,
} from 'store/actions/list';
import { addMessage } from 'store/actions/messages';
import { Box, Button, Heading } from 'grommet';
import { URLBuild as handleCiviURLBuild } from 'helpers/CiviCRM';
import activityStatusList from 'helpers/Filter/activityStatuses';
import CheckAvailability from './HousingNavigationWalkIn/CheckAvailability';
import * as moment from 'moment-timezone';
import ConfirmCancel from './HousingNavigationWalkIn/ConfirmCancel';

class HousingNavigationWalkIn extends Component {

  constructor(props){
    super(props);
    this.state = {

      loading: false,
      checkAvailabilityOpen: false,
      
      cancelOpen: false,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate() {
    this.loadData();
  }

  calculateCurrentTableState = () => {
    const tableState = [
      this.props.contactId,
      this.props.sortBy.map(value => value.columnName + ' ' + value.direction).join(','),
      this.props.pageSize,
      this.props.currentPage,
    ].join('|');
    return tableState;
  }

  /**
   * Load our housing navigation appointments
   */
  loadData = () => {
        
    const currentTableState = this.calculateCurrentTableState();
    const { contactId, tableState, loadingTableState } = this.props;
    if (
      currentTableState === tableState ||
      currentTableState === loadingTableState
    ) return;

    this.props.updateLoadingListState(currentTableState);
    this.setState({ loading: true });

    const start = moment().tz('America/Los_Angeles').startOf('day');
    const end = moment().tz('America/Los_Angeles').endOf('day');
    // console.log({ startDate, endDate, start, end })

    const url = handleCiviURLBuild('Request','get', {
      sequential: 1,
      target_contact_id: contactId,
      activity_type_id: '179', // housing navigation walk in
      activity_date_time: {
        BETWEEN: [start.format('YYYY-MM-DD HH:mm:ss'), end.format('YYYY-MM-DD HH:mm:ss')]
      },
      status_id: {
        IN: ['Scheduled', 'Completed', 'Requested'],
      },
      options:{
        limit :0,
      },
    });
    
    return fetch(url, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
    .then(response => response.json())
    .then(json => {
      if (json.is_error)
        throw new Error(json.error_message);
      // console.log(json)
      return json;
    })
    .then(data => data.values)
    .then(data => {
      this.props.updateCurrentListState(currentTableState);
      this.props.setListData({ data });
      this.props.updateLoadingListState('');
      this.setState({ loading: false });
    })
    .catch(e => {
      this.setState({ loading: false });
      this.props.handleMessage(e.message, 'error');
    });
  }

  toggleCheckAvailablityOpen = (state=null) => 
    this.setState({ checkAvailabilityOpen: state===null ? !this.state.checkAvailabilityOpen : state })
  toggleConfirmCancelOpen = (state=null, cancelAppointment=null) => 
    this.setState({ cancelOpen: state===null ? !this.state.cancelOpen : state })

  render() {

    const { data } = this.props;
    const { loading } = this.state;

    // const now = moment().tz("America/Los_Angeles");

    const { cancelOpen } = this.state;

    let content;
    if (loading || data===null) {
      content = <div>Loading...</div>;
    } else {
      switch (data.length) {
        case 0:
          content = (
            <div>
              <Button label="Check Availability" color="neutral-3" primary={true} onClick={() => this.toggleCheckAvailablityOpen(true)} />
              <CheckAvailability open={this.state.checkAvailabilityOpen} toggle={this.toggleCheckAvailablityOpen} />
            </div>
          );
          break;
        case 1:
          const activity = data[0];
          const { activity_date_time, status_id } = activity;
          const apptTime = moment.tz(activity_date_time, 'YYYY-MM-DD HH:mm:ss', "America/Los_Angeles");
          const apptStatus = activityStatusList.find(status => status.value === status_id);
          content = (
            <div>
              <h3 style={{ marginTop: 0 }}>
                {apptStatus ? apptStatus.label : 'Appointment'} at {apptTime.format('h:mm a').replace('am', 'a.m.').replace('pm', 'p.m.')}
              </h3>
              <Button label="Cancel Appointment" color="neutral-3" primary={true} onClick={() => this.toggleConfirmCancelOpen(true)} />
              <ConfirmCancel
                open={cancelOpen}
                activity={activity}
                toggle={this.toggleConfirmCancelOpen}
              />
            </div>
          );
          break;
        default:
          content = (
            <div>
              Multiple appointments scheduled, please see desk
            </div>
          );
          break;
      }
    }

    return (
      <Box className="HousingNavigationWalkIn">
        <Box background="brand" flex="grow" pad={{ left: 'medium', right: 'small', vertical: 'small' }}>
          <Heading level="3" style={{ margin: 0, textAlign: 'center' }}>
            Housing Navigation Walk-In Services
          </Heading>
        </Box>
        <Box className="content" pad="medium" fill justify="center">
          {content}
        </Box>
      </Box>
    );
  }
}

const listName = 'housingnav';

const mapStateToProps = (state) => {

  const {
    auth: { contact, contactId },
    list: { housingnav }
  } = state;

  return {
    contactId,
    contact,
    ...housingnav,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    handleMessage: (message, variant=null, logout=false) => dispatch(addMessage(message, variant, logout)),
    
    updateCurrentPage: currentPage => dispatch(updateCurrentPage(listName, currentPage)),
    updatePageSize: pageSize => dispatch(updatePageSize(listName, pageSize)),
    updateSorting: sortBy => dispatch(updateSorting(listName, sortBy)),
    updateSearchValue: searchValue => dispatch(updateSearchValue(listName, searchValue)),
    updateCurrentListState: tableState => dispatch(updateCurrentListState(listName, tableState)),
    updateLoadingListState: (loadingTableState) => dispatch(updateLoadingListState(listName, loadingTableState)),
    setListData: data => dispatch(setListData(listName, data)),
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(HousingNavigationWalkIn);