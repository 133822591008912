import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Box, Button, Heading, Layer } from 'grommet';
import { Checkbox, Close, CheckboxSelected } from 'grommet-icons';
import * as moment from 'moment-timezone';
import { cloneDeep } from 'lodash-es';
import activityStatusList from 'helpers/Filter/activityStatuses';
import { timeDisplay } from 'helpers/CiviCRM';

class MySchedule extends Component {

  render() {

    const { checkinData, contact, pData, cData, hData, loaded, open, toggle } = this.props;

    if (!open)
      return null;

    const { checkins, registrations } = loaded ? checkinData : {};

    // console.log({checkins, registrations});

    // Join check in registrations with dropin
    const participations = !pData ? [] :
      cloneDeep(pData) // dont accidentally change our loaded data
      // filter out things we are attending based on current form data
      .filter(event => {
        const isRegistered = event['api.Participant.get'].values
          .filter(participation => {
            return ['registered','registereddropin','pendingattended'].includes(participation.checkinStatus);
          })
          .length>0;
        // console.log(`register_${event.id}` in registrations, registrations[`register_${event.id}`])
        const isDropinAndChecked = 
          event.checkinStatus==='dropin' && 
          registrations &&
          `register_${event.id}` in registrations && 
          registrations[`register_${event.id}`];
        // console.log({isDropinAndChecked})
        return isRegistered || isDropinAndChecked;
      })
      .map(event => {
        // check over our registrations for stuff we check
        if (event['api.Participant.get'].values.length>0)
          return event['api.Participant.get'].values.map(participation => {
            const newParticipation = {...participation};
            // console.log(`checkin_${participation.id}` in checkins, checkins[`checkin_${participation.id}`])
            if (checkins && `checkin_${participation.id}` in checkins && checkins[`checkin_${participation.id}`]){
              // console.log(newParticipation.checkinStatus)
              newParticipation.checkinStatus = 'pendingattended';
            } else {
              newParticipation.checkinStatus = 'registered';
            }
            return newParticipation;
          });
        // this is some event we will be attending (eg dropin)
        return event;
      })
      .flat();

    if (cData) {
      cloneDeep(cData) // dont accidentally change our loaded data
      .forEach(activity => {
        const { id, activity_date_time, duration, subject, status_id } = activity;
        const checkinStatus = activityStatusList.find(status => status.value === status_id);
        // map our activity to look like an event
        participations.push({
          id: 'casemgmt_' + id,
          event_start_date: activity_date_time,
          event_end_date: duration ? 
            moment.tz(activity_date_time, 'YYYY-MM-DD HH:mm:ss', "America/Los_Angeles")
              .add(parseInt(duration), 'minute')
              .format('YYYY-MM-DD HH:mm:ss') : 
              '',
          event_title: subject,
          event_location: '',
          checkinStatus: checkinStatus ? checkinStatus.label : '',
        });
      });
    }

    if (hData) {
      cloneDeep(hData) // dont accidentally change our loaded data
      .forEach(activity => {
        const { id, activity_date_time, duration, subject, status_id } = activity;
        const checkinStatus = activityStatusList.find(status => status.value === status_id);
        // map our activity to look like an event
        participations.push({
          id: 'housingnav_' + id,
          event_start_date: activity_date_time,
          event_end_date: duration ? 
            moment.tz(activity_date_time, 'YYYY-MM-DD HH:mm:ss', "America/Los_Angeles")
              .add(parseInt(duration), 'minute')
              .format('YYYY-MM-DD HH:mm:ss') : 
              '',
          event_title: subject,
          event_location: '',
          checkinStatus: checkinStatus ? checkinStatus.label : '',
        });
      });
    }

    participations.sort((a,b) => {
      const aStart = moment.tz(a.event_start_date, 'YYYY-MM-DD HH:mm:ss', "America/Los_Angeles");
      const bStart = moment.tz(b.event_start_date, 'YYYY-MM-DD HH:mm:ss', "America/Los_Angeles");
      if (aStart.isSame(bStart))
        return 0;
      if (aStart.isBefore(bStart))
        return -1;
      return 1;
    });

    const now = moment().tz("America/Los_Angeles");

    return (
      <Layer
        className="MySchedule"
        margin="large"
        model={true}
        onClickOutside={() => toggle(false)}
        onEsc={() => toggle(false)}
        plain={false}
        responsive={true}
        full={true}
      >
        <Box fill>
          <Box
            direction='row'
            align='center'
            justify='between'
            background="light-2"
            pad={{ left: 'medium', right: 'small', vertical: 'small' }}
            elevation='medium'
            style={{ zIndex: '1' }}
          >
            <Box flex>
              <Heading level="2" style={{ margin: 0 }}>
                {contact && contact.display_name} - Schedule for {now.format('MMMM Do')}
              </Heading>
            </Box>
            <Box>
              <Button icon={<Close />} label="Close" onClick={() => toggle(false)} />
            </Box>
          </Box>
          <Box fill pad="medium" style={{ overflowY: 'auto' }}>
            <Box justify="center" direction="row">
              <Box direction="column" className="schedule">
                <Box background="dark-1" direction="row">
                  <Box pad="small" basis="large" border={{ size: "medium", color: "dark-1", side: "left" }}>
                    Time
                  </Box>
                  <Box pad="small" basis="xxlarge" border={{ size: "medium", color: "dark-1", side: "left" }}>
                    Title / Location
                  </Box>
                  <Box pad="small" basis="medium" border={{ size: "medium", color: "dark-1", side: "left" }}>
                    Checked In
                  </Box>
                </Box>
                {participations.length>0 ? participations.map((participation,i) => {
                  return (
                    <Box key={participation.id} background={i%2 === 0 ? "light-6" : "light-1"} direction="row">
                      <Box pad="small" basis="large" border={{ size: "medium", color: "dark-1", side: "left" }}>
                        {timeDisplay(participation)}
                      </Box>
                      <Box pad="small" basis="xxlarge" border={{ size: "medium", color: "dark-1", side: "left" }}>
                        {participation.event_title}
                        <br />
                        <small>
                          {participation.event_location || participation.room_location ? 
                            <span style={{ marginRight: 12 }}>
                              {participation.event_location}
                              {participation.event_location && participation.room_location && ' / '}
                              {participation.room_location}
                            </span> 
                          : '-- ask for location --'}
                        </small>
                      </Box>
                      <Box pad="small" basis="medium" border={{ size: "medium", color: "dark-1", side: "left" }} justify="center" align="center">
                        {['Scheduled', 'Completed', 'Requested'].includes(participation.checkinStatus) ? participation.checkinStatus :
                          (['pendingattended', 'dropin'].includes(participation.checkinStatus) ? <CheckboxSelected size="large" /> : <Checkbox size="large" />)
                        }
                      </Box>
                    </Box>
                  );
                }) :
                  <Heading level="3" style={{ magin: 0 }}>-- No Events -- </Heading>
                }
              </Box>
            </Box>
            <Box pad="medium" margin="medium" justify="center" direction="row">
              <Box>
                <Button margin="small" label="OK" type="submit" primary={true} onClick={() => toggle(false)} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Layer>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth: { contact }, form, list: { participations, casemgmt, housingnav }} = state;

  const loaded = 'checkin' in form && 'values' in form['checkin'];

  return {
    contact,
    // participations
    pData: participations.data,
    pTableState: participations.tableState,
    pLoadingTableState: participations.loadingTableState,
    // casemgmt
    cData: casemgmt.data,
    cTableState: casemgmt.tableState,
    cLoadingTableState: casemgmt.loadingTableState,
    // housingnav
    hData: housingnav.data,
    hTableState: housingnav.tableState,
    hLoadingTableState: housingnav.loadingTableState,
    // check in form
    checkinData: loaded && form['checkin'].values,
    loaded,
  };
}

const mapDispatchToProps = dispatch => {
  return {
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(MySchedule);