import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change, Field } from 'redux-form';
import { addMessage } from 'store/actions/messages';
import { Box } from 'grommet';
import { timeDisplay } from 'helpers/CiviCRM';
import { renderCheckBox } from 'helpers/FormFields';

class EventDisplay extends Component {

  rowClick = (event, name, currentValue) => this.props.change(name, !currentValue)

  render() {

    const { event } = this.props;

    /**
     * TODO: this can create duplicate registrations
     */
    // technically already registered if we have a contact_id in our object
    const name = 'contact_id' in event ? `checkins[checkin_${event.id}]` : `registrations[register_${event.id}]`;
    let currentValue = false;

    return (
      <Box
        className="EventDisplay"
        pad="small"
        border={{ side: "all", size: "medium" }}
        direction="row"
        onClick={e => this.rowClick(event, name, currentValue)}
      >
        <Box
          flex={false}
          justify="center"
        >
          <Field
            // label={event.event_title}
            name={name} 
            component={props =>{
              currentValue = props.input.value;
              return renderCheckBox(props);
            }}
          />
        </Box>
        <Box className="info" flex justify="center">
          <div>
            {event.event_title}
            <br />
            <small>
              {event.event_location || event.room_location ? 
                <span style={{ marginRight: 12 }}>
                  {event.event_location}
                  {event.event_location && event.room_location && ' / '}
                  {event.room_location}
                </span> 
              : '-- ask for location --'}
              <br />
              {timeDisplay(event)}
            </small>
          </div>
        </Box>
      </Box>
    );
  }
}

const formName = 'checkin';

const mapStateToProps = (state) => {
  const { mobileMode } = state.display;
  return {
    mobileMode,
  };
}

const mapDispatchToProps = dispatch => {

  return {
    handleMessage: (message, variant=null, logout=false) => dispatch(addMessage(message, variant, logout)),
    change: (field, value) => dispatch(change(formName, field, value)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventDisplay);