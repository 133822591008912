// Redux action types.
const constants = {
  LOCAL_STORAGE_KEY:              'SENIORS_KIOSK_APP',

  // auth
  AUTH_SETUP:                     'AUTH_SETUP',
  TEST_MODE:                      'TEST_MODE',
  TRY_LOGIN:                      'TRY_LOGIN',
  FAIL_LOGIN:                     'FAIL_LOGIN',
  LOGIN:                          'LOGIN',
  LOGOUT:                         'LOGOUT',
  CHECK_IN:                       'CHECK_IN',

  // display
  WINDOW_RESIZE:                  'WINDOW_RESIZE',

  // load
  STATUS_MAP:                     'STATUS_MAP',
  KIOSK_LOCATION:                 'KIOSK_LOCATION',

  // list
  LIST_CURRENT_PAGE:              'LIST_CURRENT_PAGE',
  LIST_PAGE_SIZE:                 'LIST_PAGE_SIZE',
  LIST_SORTING:                   'LIST_SORTING',
  LIST_SEARCH_VALUE:              'LIST_SEARCH_VALUE',
  LIST_DATES:                     'LIST_DATES',
  LIST_TABLE_STATE:               'LIST_TABLE_STATE',
  LIST_LOADING_TABLE_STATE:       'LIST_LOADING_TABLE_STATE',
  LIST_DATA:                      'LIST_DATA',
  LIST_DATA_UPDATE:               'LIST_DATA_UPDATE',

  // single
  SINGLE_DATA:                    'SINGLE_DATA',
  SINGLE_STATE:                   'SINGLE_STATE',
  SINGLE_LOADING_STATE:           'SINGLE_LOADING_STATE',

  // messages
  ADD_MESSAGE:                    'ADD_MESSAGE',
  CLEAR_SINGLE_MESSAGE:           'CLEAR_SINGLE_MESSAGE',
  CLEAR_ALL_MESSAGES:             'CLEAR_ALL_MESSAGES',
};

export default constants;
