import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from 'store/actions/auth';
import {
  updateCurrentPage, 
  updatePageSize, 
  updateSorting, 
  updateSearchValue, 
  updateCurrentListState,
  updateLoadingListState,
  setListData,
  updateListData,
} from 'store/actions/list';
import { addMessage } from 'store/actions/messages';
import { Form, reduxForm } from 'redux-form';
import { Box } from 'grommet';
import * as moment from 'moment-timezone';
// import {default as uuid} from "uuid";
import './Checkin/styles.css';
import { URLBuild as handleCiviURLBuild } from 'helpers/CiviCRM';
import { participationStatus, registrationStatus } from 'helpers/CiviCRM';
import CheckIntoYourEvents from './Checkin/CheckIntoYourEvents';
import CaseManagementWalkIn from './Checkin/CaseManagementWalkIn';
import HousingNavigationWalkIn from './Checkin/HousingNavigationWalkIn';
import DropInEvents from './Checkin/DropInEvents';
import Map from './Checkin/Map';
import MyScheduleButton from './Checkin/MySchedule/MyScheduleButton';
import DataEnrichmentWeHo from './Checkin/DataEnrichment/WeHo';
import DataEnrichmentDemographics from './Checkin/DataEnrichment/Demographics';
import StillHere from './Checkin/StillHere';
import WelcomeHeading from './Checkin/WelcomeHeading';
import beep from 'assets/audio/beep';
import locationsList from 'helpers/Filter/locations';
import roomsList from 'helpers/Filter/rooms';

const handlers = {
  'click': null,
  'scroll': null,
  'touchstart': null,
  'touchend': null,
  'touchmove': null,
  'touchcancel': null,
  'keydown': null,
  'keyup': null,
};

class Checkin extends Component {

  constructor(props){
    super(props);
    this.state = {
      dataEnrichmentWeHoOpen: false,
      dataEnrichmentDemographicsOpen: false,
      loading: props.data===null,
      stillHereTimeout: null,
      stillHereOpen: false,
      scheduleOpen: false,
    };
  }

  componentDidMount() {
    
    this.loadData();

    // setup events to trigger still here
    this.setupStillHereTimeout();
    const handler = e => this.setupStillHereTimeout();
    Object.keys(handlers).forEach(eventName => {
      handlers[eventName] = handler;
      window.addEventListener(eventName, handler, true);
    });
  }

  componentDidUpdate() {
    this.loadData();
  }

  componentWillUnmount() {

    if (this.state.stillHereTimeout!==null) {
      clearTimeout(this.state.stillHereTimeout);
    }

    // remove events that trigger still here
    Object.keys(handlers).forEach(eventName => {
      handlers[eventName] && window.removeEventListener(eventName, handlers[eventName], true);
    });

    // clear state to prevent event flash on reload
    this.props.updateCurrentListState('');
    this.props.setListData({ data: null });
  }

  calculateCurrentTableState = () => {
    const tableState = [
      this.props.contactId,
      this.props.searchValue,
      this.props.sortBy.map(value => value.columnName + ' ' + value.direction).join(','),
      // this.props.count === null? 'null' : this.props.count,
      this.props.pageSize,
      this.props.currentPage,
    ].join('|');
    return tableState;
  }

  loadData = () => {
    
    const currentTableState = this.calculateCurrentTableState();
    const { tableState, loadingTableState } = this.props;
    // console.log({ currentTableState, tableState, loadingTableState})
    if (
      currentTableState === tableState ||
      currentTableState === loadingTableState
    ) return;

    this.props.updateLoadingListState(currentTableState);
    this.setState({ loading: true });

    const { statusMap } = this.props;
    // console.log(contact);

    const start = moment().tz('America/Los_Angeles').startOf('day');
    const end = moment().tz('America/Los_Angeles').endOf('day');
    // console.log({ start, end })

    const returnFields = [
      "event_title",
      "summary",
      "description",
      "event_type_id",
      "event_type",
      "event_start_date",
      "event_end_date",
      "is_online_registration",
      "is_full",
      "available_seats",
      "max_participants",
      "custom_1198", // Event Location
      "custom_1205", // Other Event Location
      "custom_1206", // Other Event Location Address
      "custom_1246", // Drop In Allowed
      "custom_1248", // closed group
      "custom_1305", // Room / Location
    ];

    const url = handleCiviURLBuild('PublicEvent','get',{
      sequential:1,
      start_date: {
        BETWEEN: [start.format('YYYY-MM-DD HH:mm:ss'),end.format('YYYY-MM-DD HH:mm:ss')]
      },
      // end_date: {
      //   BETWEEN: [start.format('YYYY-MM-DD HH:mm:ss'),end.format('YYYY-MM-DD HH:mm:ss')]
      // },
      return: returnFields,
      'api.Participant.get': {
        sequential: 1,
        event_id: "$value.id",
        contact_id: "user_contact_id",
      },
      options:{
        // or :[
        //   ["start_date","end_date"]
        // ],
        limit :0,
      },
    });
    // console.log({ url })
    
    return fetch(url, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
    .then(response => response.json())
    .then(json => {
      if (json.is_error)
        throw new Error(json.error_message);
      // console.log(json)
      return json;
    })
    .then(data => data.values.map(event => {

      // civicrm is inconsistant in returning variables
      returnFields.forEach(field => {
        if (!(field in event))
          event[field] = '';
      });

      const { custom_1198, custom_1205, custom_1206, custom_1305 } = event;

      const eventLocation = locationsList.find(location => location.value===custom_1198);
      const roomLocation = roomsList.find(room => room.value===custom_1305);

      event.checkinStatus = registrationStatus(event); // add in our statuses

      event.event_location_value = eventLocation ? eventLocation.value : null;
      event.event_location = eventLocation && eventLocation!=='Other' ? 
        eventLocation.label : 
        ( custom_1205.length>0 ? custom_1205 /* + (custom_1206.length>0 ? ' (' + custom_1206 + ')' : '') */ : '' );
      event.event_location_address = custom_1206;

      // room number
      event.room_location_value = roomLocation ? roomLocation.value : null;
      event.room_location = roomLocation ? roomLocation.label : null;
      
      event['api.Participant.get'].values =
        event['api.Participant.get'].values
        .map(p => {

          // copy event stuff to participation
          returnFields.forEach(field => {
            p[field] = event[field];
          });

          p.checkinStatus = participationStatus(p, statusMap);

          p.event_location_value = event.event_location_value;
          p.event_location = event.event_location;
          p.event_location_address = event.event_location_address;

          p.room_location_value = event.room_location_value;
          p.room_location = event.room_location;

          return p;
        });

      return event;
    })) 
    .then(data => {
      // console.log(data);
      // update list data
      this.props.updateCurrentListState(currentTableState);
      this.props.setListData({ data });
      this.props.updateLoadingListState('');
      // update form data
      const formData = {
        checkins: {},
        registrations: {},
      };
      // we are checked if we already checked in, dont initalize all because it will change statuses unnecessarily
      data.forEach(event => {
        event['api.Participant.get'].values.forEach(participation => {
          if (participation.checkinStatus==="pendingattended") {
            formData.checkins["checkin_" + participation.id] = true
          }
        })
      });

      this.props.initialize(formData);
      // done loading
      this.setState({ loading: false });
    })
    .catch(e => {
      this.setState({ loading: false });
      this.props.handleMessage(e.message, 'error');
    });
    
  }

  setupStillHereTimeout = () => {

    if (this.state.stillHereTimeout!==null) {
      clearTimeout(this.state.stillHereTimeout);
    }

    const stillHereTimeout = setTimeout(() => this.toggleStillHere(), 60000); // 60 seconds
    this.setState({ stillHereTimeout });
  }

  toggleStillHere = (state=null) => {

    const stillHereOpen = state===null ? !this.state.stillHereOpen : state;
    if (!stillHereOpen) {
      this.setupStillHereTimeout();
    }
    this.setState({ stillHereOpen });
  }

  toggleSchedule = (state=null) => this.setState({ scheduleOpen: state===null ? !this.state.scheduleOpen : state })

  render() {

    const { data, handleSubmit, pristine } = this.props;
    const { dataEnrichmentWeHoOpen, dataEnrichmentDemographicsOpen, stillHereOpen, scheduleOpen } = this.state;
    const position = data===null ? 'center' : 'start';

    const { REACT_APP_DATA_ENRICHMENT_WEHO, REACT_APP_DATA_ENRICHMENT_DEMOGRAPHICS } = process.env;

    return (
      <Box className="Checkin" direction='column' flex align={position} justify={position} elevation='small'>
        {data==null ? 
          <Box>Loading...</Box>
        : (
          <Box fill>
            <div>
              <WelcomeHeading disabled={pristine} />
            </div>
            <div>
              <Box flex>
                <Box direction="row-responsive">
                  <Box basis="2/3" margin="small">
                    <Form onSubmit={handleSubmit}>
                      <CheckIntoYourEvents />
                      <br />
                      <DropInEvents />
                    </Form>
                  </Box>
                  <Box basis="1/3" margin="small">
                    <CaseManagementWalkIn />
                    <br />
                    <HousingNavigationWalkIn />
                  </Box>
                </Box>
              </Box>
              <MyScheduleButton open={scheduleOpen} toggle={this.toggleSchedule} />
            </div>
          </Box>
        )}
        <Map />
        {REACT_APP_DATA_ENRICHMENT_WEHO==='true' && <DataEnrichmentWeHo open={dataEnrichmentWeHoOpen} toggle={newState => this.setState({ dataEnrichmentWeHoOpen: newState })} />}
        {REACT_APP_DATA_ENRICHMENT_DEMOGRAPHICS==='true' && <DataEnrichmentDemographics open={dataEnrichmentDemographicsOpen} toggle={newState => this.setState({ dataEnrichmentDemographicsOpen: newState })} />}
        <StillHere open={stillHereOpen} toggle={this.toggleStillHere} />
      </Box>
    );
  }
}

const listName = 'participations';
const formName = 'checkin';

const validate = vals => {
  const errors = {};

  if (
    (!('checkin' in vals) || Object.keys(vals.checkin).length<1) &&
    (!('registrations' in vals) || Object.keys(vals.registrations).length<1) 
  ) {
    errors.checkin = 'At least one check in or registration must be selected';
  }

  return errors;
}

const mapStateToProps = (state) => {
  const { 
    auth: { contact, contactId },
    display: { mobileMode },
    load: { statusMap },
  } = state;
  
  return {
    contact,
    contactId,
    mobileMode,
    statusMap,

    currentValues: typeof state.form[formName] !== 'undefined' && 'values' in state.form[formName] ? state.form[formName].values : null,

    ...state.list[listName],
  };
}

const mapDispatchToProps = dispatch => {

  const handleLogout = () => dispatch(logout());
  const handleMessage = (message, variant=null, logout=false) => dispatch(addMessage(message, variant, logout));
  const handleupdateCurrentListState = tableState => dispatch(updateCurrentListState(listName, tableState));
  const handleUpdateListData = updates => dispatch(updateListData(listName, updates, (current, update) => {
    // console.log(current.id, update.id, current.id===update.id);
    return current.id===update.id
  }, true));

  return {
    handleLogout,
    handleMessage,

    onSubmit: data => {

      // console.log({data})

      const requests = [];
      
      Object.keys(data.checkins).forEach(key => {

        // console.log(data.checkins[key]);

        const parts = key.split('_');
        const participatnt_id = parts[1];
        const status_id = data.checkins[key] ? 25 : 1; // pending attended, registered

        const url = handleCiviURLBuild('Registration', 'create');
        const json = {
          sequential: 1,
          id: participatnt_id,
          status_id, 
        };

        const formData = new FormData();
        formData.append('json', JSON.stringify(json));
  
        const request = fetch(url,{
          method: 'POST',
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          },
          body: formData,
        })
        .then(response => response.json())
        .then(json => {
          if (json.is_error)
            throw new Error(json.error_message);
        })
        .catch((e) => {
          handleMessage(e.message, 'error');
        });

        requests.push(request);
      });

      Object.keys(data.registrations).forEach(key => {

        // console.log(data.registrations[key]);

        // not doing anything with this registration
        if (!data.registrations[key]) return;

        const parts = key.split('_');
        const event_id = parts[1];
        const status_id = 25; // pending attended

        const url = handleCiviURLBuild('Registration', 'create');
        const json = {
          sequential: 1,
          event_id,
          status_id, 
        };

        const formData = new FormData();
        formData.append('json', JSON.stringify(json));
  
        const request = fetch(url, {
          method: 'POST',
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          },
          body: formData,
        })
        .then(response => response.json())
        .then(json => {
          if (json.is_error)
            throw new Error(json.error_message);
        })
        .catch((e) => {
          handleMessage(e.message, 'error');
        });

        requests.push(request);
      });

      Promise
      .all(requests)
      // .then(() => handleMessage('Check in statuses updated!', 'success', true))
      .finally(() => {
        // console.log("asdf")
        // handleupdateCurrentListState(uuid.v4()); // force reload data -- done with a logout action
        beep.play();
        handleLogout();
      });
    },

    updateCurrentPage: currentPage => dispatch(updateCurrentPage(listName, currentPage)),
    updatePageSize: pageSize => dispatch(updatePageSize(listName, pageSize)),
    updateSorting: sortBy => dispatch(updateSorting(listName, sortBy)),
    updateSearchValue: searchValue => dispatch(updateSearchValue(listName, searchValue)),
    updateCurrentListState: handleupdateCurrentListState,
    updateLoadingListState: (loadingTableState) => dispatch(updateLoadingListState(listName, loadingTableState)),
    setListData: data => dispatch(setListData(listName, data)),
    updateListData: handleUpdateListData,
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: formName,
    initialValues: {},
    validate,
  })(Checkin)
);
