import React from 'react';
import { Box, Button } from 'grommet';
import MySchedule from './MySchedule';

const MyScheduleButton = (props) => {

  const { open, toggle } = props;

  return (
    <Box className="MyScheduleButton" direction="row" justify="center">
      <Box>
        <Button primary={true} margin="small" label="Show My Schedule" color="neutral-3" onClick={() => toggle(true)} />
        <MySchedule open={open} toggle={toggle} />
      </Box>
    </Box>
  );
}

export default MyScheduleButton;