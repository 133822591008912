
const housingNavigationTimeOptions = [
  {
    label: '10-10:45',
    value: '10:00:00',
    duration: '45',
  },
  {
    label: '11-11:45',
    value: '11:00:00',
    duration: '45',
  },
  {
    label: '12-12:45',
    value: '12:00:00',
    duration: '45',
  },
  {
    label: '1:30-2:15',
    value: '13:30:00',
    duration: '45',
  },
  {
    label: '2:30-3:15',
    value: '14:30:00',
    duration: '45',
  },
  {
    label: '3:30-4:15',
    value: '15:30:00',
    duration: '45',
  },
];

export default housingNavigationTimeOptions;