import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  updateCurrentPage, 
  updatePageSize, 
  updateSorting, 
  updateSearchValue, 
  updateCurrentListState,
  updateLoadingListState,
  setListData,
} from 'store/actions/list';
import { addMessage } from 'store/actions/messages';
import { Field, Form, reduxForm, SubmissionError, submit } from 'redux-form';
import { Box, Button, Heading, Layer, Text } from 'grommet';
import { Close, StatusGood } from 'grommet-icons';
import { 
  renderFormField,
  // renderRadioButton,
  renderRadioButtonGroup,
  renderCheckBox,
} from 'helpers/FormFields';
import { URLBuild as handleCiviURLBuild } from 'helpers/CiviCRM';
import caseManagementTimeOptionsList from 'helpers/Filter/caseManagementTimeOptions';
import caseManagementServicesList from 'helpers/Filter/caseManagementServices';
import theme from 'theme';
import beep from 'assets/audio/beep';
import * as moment from 'moment-timezone';
import ConfirmClose from './ConfirmClose';

class CheckAvailability extends Component {

  constructor(props){
    super(props);
    this.state = {
      loading: false,
      availableTimeSlots: [],
      confirmCloseOpen: false,
    };
  }

  componentDidMount() {

    if (this.props.open)
      this.loadData();
      
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {

    const { casemgmt_unavailable_data, handleMessage, open, toggle, kioskLocation } = this.props;
    
    if (open)
      this.loadData();

    let noAppointments = false;
    if (casemgmt_unavailable_data!==null) {
      if (
          prevProps.casemgmt_unavailable_data===null || 
          casemgmt_unavailable_data.map(v => v.activity_date_time + ',' + v.duration).join('|') !==
          prevProps.casemgmt_unavailable_data.map(v => v.activity_date_time + ',' + v.duration).join('|')
      ) {
        const availableTimeSlots = this.calculateAvailableTimeSlots();
        this.setState({ availableTimeSlots });
        noAppointments = availableTimeSlots.length===0;
      } else {
        noAppointments = this.state.availableTimeSlots.length===0;
      }
    }

    // if (open && (noAppointments || kioskLocation != "1")) {
    if (open) { // temporarily disabled case management walk-ins until a new staff member is found for that role
      toggle(false);
      handleMessage(
        <div>
          <p>There are currently no appointments available for case management at this location.</p>
          <p>If you need immediate assistance today, please speak with the front desk.</p>
          <p>If you have an assigned case manager, please contact them to schedule an appointment.</p>
        </div>
      , 'info');
    }

  }

  calculateCurrentTableState = () => {
    const tableState = [
      this.props.contactId,
      this.props.casemgmt_unavailable.sortBy.map(value => value.columnName + ' ' + value.direction).join(','),
      this.props.casemgmt_unavailable.pageSize,
      this.props.casemgmt_unavailable.currentPage,
    ].join('|');
    return tableState;
  }

  /**
   * Calculate the available timeslots from the unavailble slots we loaded
   */
  calculateAvailableTimeSlots = () => {

    const { casemgmt_unavailable_data } = this.props;

    // console.log(casemgmt_unavailable_data)

    const now = moment().tz("America/Los_Angeles");
    const dow = now.day();

    // no appointments on sunday (and friday only gets one type of service)
    if (dow===0) // 0 sunday
      return [];

    const availableTimeSlots = caseManagementTimeOptionsList.filter(timeSlotOption => {
      
      const timeParts = timeSlotOption.value.split(':');
      const timeSlot = moment().tz("America/Los_Angeles").set({ hour: timeParts[0], minute: timeParts[1], second: timeParts[2] });
      
      if (!timeSlot.isAfter(now))
        return false;

      const timeSlotEnd = timeSlot.clone().add(timeSlotOption.duration, 'minute');
      const overlapping = casemgmt_unavailable_data.filter(appt => {
      
        const apptDateTime = moment.tz(appt.activity_date_time, 'YYYY-MM-DD HH:mm:ss', "America/Los_Angeles");
        const apptDateTimeEnd = apptDateTime.clone().add(appt.duration || '45', 'minute');

        // our time slot ends before this appointment or our time slot starts after this appt ends
        const itsAvailable = timeSlotEnd.isBefore(apptDateTime) || timeSlot.isAfter(apptDateTimeEnd);
        // console.log('itsAvailable',  timeSlotOption.value, itsAvailable)
      
        // return false;
        return !itsAvailable;
      });

      // a flag for other veto issues
      let noOtherIssues = true;
      
      //* EDIT: turned off this filter for fridays in response to SD issue #125815
      // // on fridays we only do afternoons
      // if (dow===5 && !['13:00:00','14:00:00','15:00:00','16:00:00'].includes(timeSlotOption.value))
      //   noOtherIssues = false;
      
      // console.log('overlapping',  timeSlotOption.value, overlapping)
      return overlapping.length===0 && noOtherIssues===true;
    });

    return availableTimeSlots;
  }

  /**
   * Load unavailable timeslots
   */
  loadData = () => {
    
    const currentTableState = this.calculateCurrentTableState();
    const { casemgmt_unavailable } = this.props;
    const { tableState, loadingTableState } = casemgmt_unavailable;
    if (
      currentTableState === tableState ||
      currentTableState === loadingTableState
    ) return;

    this.props.updateLoadingListState(currentTableState);
    this.setState({ loading: true });

    const start = moment().tz('America/Los_Angeles').startOf('day');
    const end = moment().tz('America/Los_Angeles').endOf('day');
    // console.log({ startDate, endDate, start, end })

    const url = handleCiviURLBuild('Request','unavailable');
    const json = {
      sequential: 1,
      activity_date_time: {
        BETWEEN: [start.format('YYYY-MM-DD HH:mm:ss'), end.format('YYYY-MM-DD HH:mm:ss')]
      },
      status_id: {
        IN: ['Scheduled', 'Completed', 'Requested'],
      },
      activity_type_id: '163', // case management walk in
      options:{
        limit :0,
      },
    };

    const formData = new FormData();
    formData.append('json', JSON.stringify(json));
    
    return fetch(url, {
      method: 'POST',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
      body: formData,
    })
    .then(response => response.json())
    .then(json => {
      if (json.is_error)
        throw new Error(json.error_message);
      // console.log(json)
      return json;
    })
    .then(data => data.values)
    .then(data => {
      this.props.updateCurrentListState(currentTableState);
      this.props.setListData({ data });
      this.props.updateLoadingListState('');
      this.setState({ loading: false });
    })
    .catch(e => {
      this.setState({ loading: false });
      this.props.handleMessage(e.message, 'error');
    });
    
  }

  /**
   * Submit the case mgmt request
   */
  submitForm = values => {

    // console.log(values, this.props);

    const { contact, handleMessage, reset, toggle, updateCurrentListState, kioskLocation } = this.props;

    /**
     * Validate and parse out payload
     */

    // can be used for individual field errors if you throw this object in a submittions error
    const errors = {};

    const now = moment().tz("America/Los_Angeles");
    const activityTime = moment().tz("America/Los_Angeles");
    const timeSlotOption = caseManagementTimeOptionsList.find(timeSlot => timeSlot.value===values.time_slot)
    if (!timeSlotOption) {
      errors.time_slot = 'Time Slot is required';
    } else {
      const timeParts = timeSlotOption.value.split(':');
      activityTime.set({ hour: timeParts[0], minute: timeParts[1], second: timeParts[2] });
      if (activityTime.isSameOrBefore(now)) {
        errors.time_slot = 'Time Slot is not available';
      }
    }

    const custom_1201 = values.custom_1201 ?
      Object.keys(values.custom_1201)
      .filter(service => values.custom_1201[service])
      .map(service => {
        const parts = service.split('_');
        return parts[1]; // service ID
      }) : [];

    if (custom_1201.length===0)
      errors.custom_1201 = 'Reason for Request is required';

    // const caseManagementServicesOptions = custom_1201_list.length>0 &&
    //   caseManagementServicesList
    //   .filter(service => custom_1201_list.includes(service.value));

    // if (!caseManagementServicesOptions)
    //   errors.custom_1201 = 'Reason for Request is required';

    const { custom_1202 } = values;
    if (custom_1201.includes('10') && !custom_1202)
      errors.custom_1202 = 'Description required when Other Reason chosen';

    const errorKeys = Object.keys(errors);
    if (errorKeys.length>0) {
      // throw new SubmissionError(errors);
      throw new SubmissionError({
        _error: errorKeys.map(key => errors[key]).join(', '),
      });
    }

    /**
     * Submit!
     */

    const json = {
      activity_type_id: '163', // Case Management Walk In Service
      target_contact_id: contact.id,
      status_id: 'Requested',
      duration: timeSlotOption.duration,
      activity_date_time: activityTime.format('YYYY-MM-DD HH:mm:ss'),
      subject: "Kiosk - Case Management Walk in Request",
      custom_172: kioskLocation, // AMRC Campus is no longer the only location, so this needs to use current kiosk location
      custom_1201,
      custom_1202,
    };

    const formData = new FormData();
    formData.append('json', JSON.stringify(json));
  
    const url = handleCiviURLBuild('Request','create');

    return fetch(url, {
      method: "POST",
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
      body: formData,
    })
    .then(response => response.json())
    .then(json => {
      // console.log(json);
      if (json.is_error) {
       throw new Error(json.error_message);
      }
      beep.play();
      handleMessage('Your request will be reviewed. Please check in at the front desk 5-10 minutes before your appointment.', 'success');
      reset();
      toggle(false);
      updateCurrentListState(''); // invalidate current list of unavailalbe timeslots
      updateCurrentListState('', 'casemgmt'); // invalidate current loaded appointments
      return json;
    })
    .catch(error => {
      throw new SubmissionError({
        _error: error.message,
      })
    });
  }

  /**
   * Confirm the close when the form is dirty
   */
  toggleConfirmClose = (state=null, closeCheckAvailability=false) => {
    return this.setState({ 
      confirmCloseOpen: state===null ? !this.state.confirmCloseOpen : state 
    }, 
      () => {
        if (!closeCheckAvailability) return;
        this.props.toggle(false);
      }
    );
  }
  toggleWithConfirm = (state = null) => this.setState({ confirmCloseOpen: true });

  render(){

    const { data, error, handleSubmit, handleSubmitAction, open, submitting } = this.props;
    const { loading, availableTimeSlots, confirmCloseOpen } = this.state;

    if (!open)
      return null;

    const now = moment().tz("America/Los_Angeles");

    // console.log(theme)
    theme.checkBox.size = "36px";
    theme.radioButton.size = "56px";
    theme.radioButton.icon.size = "32px";

    return (
      <div className="CheckAvailability">
        <Layer
          margin="large"
          model={true}
          onClickOutside={() => this.toggleWithConfirm(false)}
          onEsc={() => this.toggleWithConfirm(false)}
          plain={false}
          responsive={true}
          full={true}
        >
          <Form onSubmit={handleSubmit(this.submitForm)}>
            <Box fill>
              <Box
                direction='row'
                align='center'
                justify='between'
                background="light-2"
                pad={{ left: 'medium', right: 'small', vertical: 'small' }}
                elevation='medium'
                style={{ zIndex: '1' }}
              >
                <Box flex>
                  <Heading level="2" margin="none">Case Management Walk-in Services</Heading>
                </Box>
                <Box>
                  <Button icon={<Close />} label="Close" onClick={() => this.toggleWithConfirm(false)} />
                </Box>
              </Box>
              {loading ?
                <Box fill style={{ padding: '24px', overflowY: 'auto' }} flex align="center" justify="center">
                  <Box>Loading...</Box>
                </Box>
              :
                <Box fill style={{ padding: '24px', overflowY: 'auto' }}>
                  <Box direction="row-responsive">
                    <Box flex pad="small">
                      <Heading level="3" margin={{ top: "none", bottom: "small" }}>Choose An Available Time Slot for {now.format('MMMM Do')}</Heading>
                      {availableTimeSlots.length===0 && <Heading level="2" color="status-critical">No Available Time Slots</Heading>}
                      {/* {caseManagementTimeOptionsList.map(timeOption => (
                        <div key={timeOption.value} style={{ marginBottom: 12 }}>
                          <Field
                            name={`time_slot`}
                            inputValue={timeOption.value}
                            component={renderRadioButton}
                            label={timeOption.label}
                            theme={theme}
                          />
                        </div>
                      ))} */}
                      <Field
                        name={`time_slot`}
                        component={renderRadioButtonGroup}
                        options={availableTimeSlots}
                        theme={theme}
                      />
                    </Box>
                    <Box flex pad="small">
                      <Heading level="3" margin={{ top: "none", bottom: "small" }}>Reason for Request</Heading>
                      {caseManagementServicesList
                      .filter(service => {
                        const now = moment().tz("America/Los_Angeles");
                        const dow = now.day();
                        // only employment on fridays
                        if ([5].includes(dow))
                           return ['2'].includes(service.value);
                        // otherwise all are fine
                        return true;
                      }).map(service => (
                        <div key={service.value} style={{ marginBottom: 12 }}>
                          <Field
                            name={`custom_1201[service_${service.value}]`}
                            component={renderCheckBox}
                            label={service.label}
                            theme={theme}
                          />
                        </div>
                      ))}
                      {data && 'custom_1201' in data && 'service_10' in data.custom_1201 && data.custom_1201.service_10 && // other is selected above
                        <Field
                          label="If other, please describe services"
                          id="custom_1202"
                          name="custom_1202"
                          component={renderFormField}
                        />
                      }
                    </Box>
                  </Box>
                  <Box justify="center" direction="row">
                    <Box style={{ textAlign: 'center' }}>
                      {/* Regular validation does not trigger when submitted through action */}
                      {error && <Text size="xxlarge" color="status-critical">{error}</Text>}
                      {/* Have to trigger submit this way or form is not connected */}
                      <div>
                        <Button
                          icon={<StatusGood />}
                          margin="small"
                          label="Submit Request"
                          type="button"
                          primary={true}
                          disabled={availableTimeSlots.length===0 || submitting}
                          onClick={handleSubmitAction}
                        />
                      </div>
                    </Box>
                  </Box>
                </Box>
              }
            </Box>
          </Form>
        </Layer>
        {confirmCloseOpen && <ConfirmClose open={confirmCloseOpen} toggle={this.toggleConfirmClose} />}
      </div>
    );
  }
}

const formName = 'casemgmt';
const listName = 'casemgmt_unavailable';
// const validate = vals => {
//   const errors = {};

//     if (!('checkin' in vals) || Object.keys(vals.checkin).length<1) {
//     errors.checkin = 'At least one registration must be selected';
//   }

//   errors.time_slot = 'ahhhhhhhhhhhhhhhhhh';
 
//   return errors;
// }

const mapStateToProps = (state) => {

  const { auth: { contact, contactId }, list: { casemgmt_unavailable }, form, load: { kioskLocation } } = state;
  const loaded = formName in form && 'values' in form[formName];

  return {
    contactId,
    contact,
    data: loaded && form[formName].values,
    loaded,
    casemgmt_unavailable,
    casemgmt_unavailable_data: casemgmt_unavailable.data, // need this so damn thing updates properly
    kioskLocation,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    handleSubmitAction: () => dispatch(submit(formName)),
    handleMessage: (message, variant=null, logout=false) => dispatch(addMessage(message, variant, logout)),
    
    updateCurrentPage: currentPage => dispatch(updateCurrentPage(listName, currentPage)),
    updatePageSize: pageSize => dispatch(updatePageSize(listName, pageSize)),
    updateSorting: sortBy => dispatch(updateSorting(listName, sortBy)),
    updateSearchValue: searchValue => dispatch(updateSearchValue(listName, searchValue)),
    updateCurrentListState: (tableState, currentList=listName) => dispatch(updateCurrentListState(currentList, tableState)),
    updateLoadingListState: (loadingTableState) => dispatch(updateLoadingListState(listName, loadingTableState)),
    setListData: data => dispatch(setListData(listName, data)),
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: formName,
    initialValues: {},
    // validate,
  })(CheckAvailability)
);