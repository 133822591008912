import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { authSetup } from 'store/actions/auth';
import { windowResize } from 'store/actions/display';
import { loadStatusMap, loadKioskLocation } from 'store/actions/load';
import { Box, Grommet } from 'grommet';
import cx from 'classnames';
import theme from 'theme';
import './App/styles.css';

import AppBar from './App/AppBar';
import Footer from './App/Footer';
import LoadingOverlay from './App/LoadingOverlay';
import Messages from './App/Messages';
import Auth from './Auth';
import Checkin from './Checkin';
import NotFound from './NotFound';

class App extends Component {

  componentDidMount() {
    
    this.props.handleAuthSetup();
    this.props.handleLoadStatusMap();
    this.props.handleKioskLocation();
    this.resize();

    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const
      w = window,
      d = document,
      documentElement = d.documentElement,
      body = d.getElementsByTagName('body')[0],
      width = w.innerWidth || documentElement.clientWidth || body.clientWidth,
      height = w.innerHeight|| documentElement.clientHeight|| body.clientHeight;
    this.props.handleWindowResize(width,height);
  }  
  
  render() {

    const { loading, testMode } = this.props;

    return (
      <Grommet full theme={theme} className={cx({ App: true, testMode })}>
        <Box fill>
          <AppBar />
          {this.props.loggedIn ?
            <Switch>
              <Route exact path="/register" component={Checkin} />
              <Route exact path='/' component={props => {
                return <Redirect to={{ pathname: "/register", state: { from: props.location } }} />;
              }} />
              <Route exact path='/login' component={props => {
                return <Redirect to={{ pathname: "/register", state: { from: props.location } }} />;
              }} />
              <Route component={NotFound} />
            </Switch>
          :
            <Switch>
              <Route exact path='/login' component={Auth} />
              <Route exact path='/' component={props => {
                return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
              }} />
              <Route exact path='/register' component={props => {
                return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
              }} />
              <Route component={NotFound} />
            </Switch>
          }
          <Footer />
          <Messages />
        </Box>
        {loading ? <LoadingOverlay /> : null}
      </Grommet>
    );
  }
}


const mapStateToProps = (state) => {
  const { isFetching, loggedIn, testMode } = state.auth;
  const { setup } = state.load;
  // console.log({ isFetching, setup, loading: isFetching || !setup})
  return {
    loading: isFetching || !setup, // show our loading overlay
    isFetching,
    loggedIn,
    setup,
    testMode,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    handleAuthSetup: () => dispatch(authSetup()),
    handleLoadStatusMap: () => dispatch(loadStatusMap()),
    handleKioskLocation: () => dispatch(loadKioskLocation()),
    handleWindowResize: (width,height) => dispatch(windowResize(width,height)),
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(App);
