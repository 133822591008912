/**
 * Used on Case Conference activity type 90
 */

const activityStatuses = [
  {
    label: 'Scheduled',
    value: '1',
  },
  {
    label: 'Completed',
    value: '2',
  },
  {
    label: 'Cancelled',
    value: '3',
  },
  {
    label: 'Left Message',
    value: '4',
  },
  {
    label: 'Unreachable',
    value: '5',
  },
  {
    label: 'Not Required',
    value: '6',
  },
  {
    label: 'Available',
    value: '7',
  },
  {
    label: 'No-show',
    value: '8',
  },
  {
    label: 'Requested',
    value: '9',
  },
];

export default activityStatuses;