import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Box, Heading } from 'grommet';
import { Next, Previous } from 'grommet-icons';
import EventDisplay from './CheckIntoYourEvents/EventDisplay';

class CheckIntoYourEvents extends Component {

  constructor(props) {
    super(props);

    this.state = {
      page: 0,
    };
  }

  render() {

    const { page } = this.state;
    const perPage = 2;

    const { data } = this.props;

    /**
     * events we are registered for
     * participations we are registered or atteded for
     */
    const participations = data === null ? [] :
      data
      .filter(event => ['registered','registereddropin'].includes(event.checkinStatus))
      .map(event =>  event['api.Participant.get'].values.filter(p => ['registered', 'pendingattended'].includes(p.checkinStatus)))
      .flat();
    // console.log({data,participations});

    return (
      <Box className="CheckIntoYourEvents">
        <Box background="brand" flex="grow" pad={{ left: 'medium', right: 'small', vertical: 'small' }}>
          <Heading level="3" style={{ margin: 0 }}>
            Check in to Your Registered Events
          </Heading>
        </Box>
        <Box className="content" fill>
          <Box direction="row" align="center">
            <Box className="navBox" pad={{ vertical: 'small' }}>
              {page>0 && <Previous className="nav" size="xlarge" onClick={() => this.setState({ page: page-1 })} />}
            </Box>
            <Box pad={{ vertical: "small" }} flex>
              {participations.length>0 ?
                <Box direction="row-responsive">
                  {participations.slice(page*perPage, (page+1)*perPage).map(participation => {
                    return (
                      <Box key={participation.id} margin="small" flex>
                        <EventDisplay participation={participation} />
                      </Box>
                    )
                  })}
                </Box>
              :
                <Box pad={{ vertical: "small" }} flex><Heading level="3">No RSVPs</Heading></Box>
              }
            </Box>
            <Box className="navBox" pad={{ vertical: 'small' }}>
              {(page+1)*perPage<participations.length && <Next className="nav" size="xlarge" onClick={() => this.setState({ page: page+1 })} />}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

const listName = 'participations';

const mapStateToProps = (state) => {
  return {
    ...state.list[listName],
  };
}

const mapDispatchToProps = dispatch => {
  return {
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(CheckIntoYourEvents);