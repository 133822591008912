import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  updateCurrentPage, 
  updatePageSize, 
  updateSorting, 
  updateSearchValue, 
  updateCurrentListState,
  updateLoadingListState,
  setListData,
} from 'store/actions/list';
import { addMessage } from 'store/actions/messages';
import { Box, Button, Heading, Layer } from 'grommet';
import * as moment from 'moment-timezone'
import { URLBuild as handleCiviURLBuild } from 'helpers/CiviCRM';

class ConfirmCancel extends Component {


  /**
   * Cancel our case management request
   */
  cancelAppointment = () => {

    // console.log(values, this.props);

    const { activity, handleMessage, toggle, updateCurrentListState } = this.props;
    const { id } = activity;

    /**
     * Submit!
     */

    const json = {
      id,
      status_id: 'Cancelled',
    };

    const formData = new FormData();
    formData.append('json', JSON.stringify(json));
  
    const url = handleCiviURLBuild('Request', 'create');

    return fetch(url, {
      method: "POST",
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
      body: formData,
    })
    .then(response => response.json())
    .then(json => {
      // console.log(json);
      if (json.is_error) {
       throw new Error(json.error_message);
      }
      handleMessage('You appointment has been cancelled.', 'success');
      toggle(false);
      updateCurrentListState(''); // invalidate current loaded appointments
      updateCurrentListState('', 'casemgmt_unavailable'); // invalidate current list of unavailalbe timeslots
      return json;
    })
    .catch(error => {
      handleMessage(`Unable to cancel appointment (${error.message}).`, 'error');
      return false;
    });    
  }

  render() {

    const { activity, open, toggle } = this.props;
    // console.log(toggle);

    if (!open || !activity)
      return null;

    const apptTime = moment.tz(activity.activity_date_time, 'YYYY-MM-DD HH:mm:ss', "America/Los_Angeles");

    return (
      <Layer
        className="ConfirmCancel"
        // full={true}
        margin="large"
        model={true}
        onClickOutside={() => toggle(false)}
        onEsc={() => toggle(false)}
        plain={false}
        responsive={true}
      >
        <Box fill>
          <Box
            direction='row'
            align='center'
            justify='between'
            background="light-2"
            pad={{ left: 'medium', right: 'small', vertical: 'small' }}
            elevation='medium'
            style={{ zIndex: '1' }}
          >
            <Box flex>
              <Heading level="2" style={{ margin: 0 }}>Are you sure?</Heading>
            </Box>
          </Box>
          <Box fill style={{ overflowY: 'auto' }}>
            <Box pad="medium" margin="medium">Are you sure you want to cancel your Case Management Appointment at {apptTime.format('h:mm a').replace('am', 'a.m.').replace('pm', 'p.m.')}?</Box>
          </Box>
          <div>
            <Box pad="medium" margin="medium" justify="center" direction="row">
              <Box>
                <Button
                  margin="small"
                  label="No"
                  onClick={() => toggle(false)}
                />
              </Box>
              <Box>
                <Button
                  margin="small"
                  label="Yes"
                  primary={true}
                  onClick={() => this.cancelAppointment()}
                />
              </Box>
            </Box>
          </div>
        </Box>
      </Layer>
    );
  }
}

const listName = 'casemgmt';

const mapStateToProps = (state) => {
  return {
  };
}

const mapDispatchToProps = dispatch => {
  return {
    handleMessage: (message, variant=null, logout=false) => dispatch(addMessage(message, variant, logout)),
    
    updateCurrentPage: currentPage => dispatch(updateCurrentPage(listName, currentPage)),
    updatePageSize: pageSize => dispatch(updatePageSize(listName, pageSize)),
    updateSorting: sortBy => dispatch(updateSorting(listName, sortBy)),
    updateSearchValue: searchValue => dispatch(updateSearchValue(listName, searchValue)),
    updateCurrentListState: (tableState, currentList=listName) => dispatch(updateCurrentListState(currentList, tableState)),
    updateLoadingListState: (loadingTableState) => dispatch(updateLoadingListState(listName, loadingTableState)),
    setListData: data => dispatch(setListData(listName, data)),
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmCancel);