import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addMessage } from 'store/actions/messages';
import { Form, initialize, reduxForm, SubmissionError } from 'redux-form';
import { setSingleData, updateCurrentState, updateLoadingState } from 'store/actions/single';
import { URLBuild as handleCiviURLBuild } from 'helpers/CiviCRM';
import './styles.css';
import { Box, Button, Heading, Layer } from 'grommet';
import { Checkmark, Close } from 'grommet-icons';
import WeHo from 'helpers/Fieldsets/WeHo';
import * as moment from 'moment-timezone';

//~ In West Hollywood:
//~ 8261 Norton Avenue 90046
//~ 1245 N Genesee Ave 90046
//~ 866 Hilldale Ave 90046

//~ Outside West Hollywood:
//~ 7928 Hollywood Blvd, 90046
//~ 800 N Genesee Ave 90046

// settings on what to ask for
const initialState = {
  somethingMissing: false,
  showLivesWeHo: null,
  showWorksWeHo: null,
  showPropertyWeHo: null,
  showSchoolWeHo: null,
  showHomelessWeHo: null,
};

class DataEnrichmentWeHo extends Component {

  constructor(props) {
    super(props);
    this.state = {...initialState};
  }

  componentDidMount() {
    /**
     * reinitialize forms on load if we already know the data, otherwise load data from CiviCRM
     */    
    const currentState = this.calculateCurrentListState();
    const { state } = this.props;
    if (state === currentState) {
      const { data } = this.props;
      this.props.initializeDataEnrichment(data);
    } else {
      this.loadData();
    }
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    this.loadData();
  }
  
  calculateCurrentListState = () => {
    const listState = [
      this.props.contactId,
    ].join('|');
    return listState;
  }

  loadData = () => {
    
    const currentState = this.calculateCurrentListState();
    const { state, loadingState, toggle } = this.props;
    // console.log({ currentState, state, loadingState })
    if (
      currentState === state ||
      currentState === loadingState
    ) return;

    const { 
      contactId,
      handleUpdateLoadingState,
      handleUpdateCurrentState,
      handleSetSingleData,
      handleMessage,
    } = this.props;

    if (typeof state === 'undefined' && typeof loadingState === 'undefined')
      return;
    // return;

    handleUpdateLoadingState(currentState);

    const url = handleCiviURLBuild('Contact','get',{
      sequential: 1,
      id: contactId,
      return: [
        /**
         * WeHo
         */
        // live
        'custom_129', // live WeHo
        'custom_286', // live weho address
        'custom_289', // live weho zip
        'custom_1364', // live weho verify date
        // work
        'custom_130', // work WeHo
        'custom_202', // work employer
        'custom_132', // work address
        'custom_135', // work address
        'custom_1365', //work weho verify date
        // property
        'custom_138', // property WeHo
        'custom_160', // property address
        'custom_163', // property zip
        'custom_1366', // property weho verify date
        // school
        'custom_146', // school WeHo
        'custom_147', // school name
        'custom_1368', // school weho verify date
        // homeless
        'custom_148', // homeless WeHo
        'custom_150', // homeless address
        'custom_153', // homeless zip
        'custom_203', // homeless description
        'custom_1367', // homeless weho verify date
      ],
    });
    
    return fetch(url, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
    .then(response => response.json())
    .then(json => {
      if (json.is_error)
        throw new Error(json.error_message);
      if (json.values.length!==1)
        throw new Error('We ran into an issue.')

      handleUpdateCurrentState(currentState);

      const account = { contact: {...json.values[0]} };

      handleSetSingleData(account);
      this.props.initializeDataEnrichment(account);

      return account;
    })
    .then(account => {

      // const { contactId } = this.props;

      // calculate which weho items needs displayed
      let {
        showLivesWeHo,
        showWorksWeHo,
        showPropertyWeHo,
        showSchoolWeHo,
        showHomelessWeHo,
      } = this.state;

      const { contact } = account;
      let somethingMissing = false;
      const updateDate = moment().tz('America/Los_Angeles').subtract(1, 'year');

      // live
      if (showLivesWeHo===null) { // not already set to show
        // blank
        const blank = contact.custom_129==="";
        // live weho address
        // live weho zip
        const missing =  contact.custom_129==="1" && (!contact.custom_286 || !contact.custom_289);
        somethingMissing = somethingMissing || missing;
        // old data
        const lastVerifyDate = contact.custom_1364==="" ? null : moment.tz(contact.custom_1364, 'YYYY-MM-DD HH:mm:ss', "America/Los_Angeles");
        const oldData = !lastVerifyDate || lastVerifyDate.isBefore(updateDate);
        // live WeHo
        showLivesWeHo = blank || missing || oldData;
      }
      // work
      if (showWorksWeHo===null) { // not already set to show
        // blank
        const blank = contact.custom_130==="";
        // work employer
        // work address
        // work address
        const missing = contact.custom_130==="1" && (!contact.custom_202 || !contact.custom_132 || !contact.custom_135);
        somethingMissing = somethingMissing || missing;
        // old data
        const lastVerifyDate = contact.custom_1365==="" ? null : moment.tz(contact.custom_1365, 'YYYY-MM-DD HH:mm:ss', "America/Los_Angeles");
        const oldData = !lastVerifyDate || lastVerifyDate.isBefore(updateDate);
        // work WeHo
        showWorksWeHo = blank || missing || oldData;
      }
      // property
      if (showPropertyWeHo===null) { // not already set to show
        // blank
        const blank = contact.custom_138==="";
        // property address
        // property zip
        const missing = contact.custom_138==="1" && (!contact.custom_160 || !contact.custom_163);
        somethingMissing = somethingMissing || missing;
        // old data
        const lastVerifyDate = contact.custom_1366==="" ? null : moment.tz(contact.custom_1366, 'YYYY-MM-DD HH:mm:ss', "America/Los_Angeles");
        const oldData = !lastVerifyDate || lastVerifyDate.isBefore(updateDate);
        // property WeHo
        showPropertyWeHo = blank || missing || oldData;
      }
      // school
      if (showSchoolWeHo===null) { // not already set to show
        // blank
        const blank = contact.custom_146==="";
        // school name
        const missing = contact.custom_146==="1" && (!contact.custom_147);
        somethingMissing = somethingMissing || missing;
        // old data
        const lastVerifyDate = contact.custom_1368==="" ? null : moment.tz(contact.custom_1368, 'YYYY-MM-DD HH:mm:ss', "America/Los_Angeles");
        const oldData = !lastVerifyDate || lastVerifyDate.isBefore(updateDate);
        // school WeHo
        showSchoolWeHo = blank || missing || oldData;
      }
      // homeless
      if (showHomelessWeHo===null) { // not already set to show
        // blank
        const blank = contact.custom_148==="";
        // homeless address
        // homeless zip
        // homeless description
        const missing = contact.custom_148==="1" && (!contact.custom_150 || !contact.custom_153 || !contact.custom_203);
        somethingMissing = somethingMissing || missing;
        // old Data
        const lastVerifyDate = contact.custom_1367==="" ? null : moment.tz(contact.custom_1367, 'YYYY-MM-DD HH:mm:ss', "America/Los_Angeles");
        const oldData = !lastVerifyDate || lastVerifyDate.isBefore(updateDate);
        // homeless WeHo
        showHomelessWeHo = blank || missing || oldData;
      }
      
      // display this dialog if necessary
      const showAtLeastOneField =
        showLivesWeHo ||
        showWorksWeHo ||
        showPropertyWeHo ||
        showSchoolWeHo ||
        showHomelessWeHo;
      this.setState({ somethingMissing, showLivesWeHo, showWorksWeHo, showPropertyWeHo, showSchoolWeHo, showHomelessWeHo });
      toggle(showAtLeastOneField);
    })
    .then(account => handleUpdateLoadingState(''))
    .catch((e) => {
      handleUpdateCurrentState(currentState);
      handleMessage(e.message, 'error');
      handleUpdateLoadingState('');
    });
  }

  submitForm = values => {

    const { handleMessage, handleUpdateCurrentState } = this.props;
    
    const contact = {...values.contact};

    /**
     * Validate
     */

    const contactErrors = {};

    // Profile
    
    /**
     * West Hollywood conditional validation
     */

    const {
      showLivesWeHo,
      showWorksWeHo,
      showPropertyWeHo,
      showSchoolWeHo,
      showHomelessWeHo,
    } = this.state;

    if (showLivesWeHo) {
      if (!contact.custom_129) { // live WeHo
        contactErrors.custom_129 = 'Required';
      } else if (contact.custom_129==="1") {
        if (!contact.custom_286)
          contactErrors.custom_286 = 'Required'; // live weho address
        if (!contact.custom_289)
          contactErrors.custom_289 = 'Required'; // live weho zip
      }
    }
  
    if (showWorksWeHo) {
      if (!contact.custom_130) { // work WeHo
        contactErrors.custom_130 = 'Required';
      } else if (contact.custom_130==="1") {
        if (!contact.custom_202)
          contactErrors.custom_202 = 'Required'; // work employer
        if (!contact.custom_132)
          contactErrors.custom_132 = 'Required'; // work address
        if (!contact.custom_135)
          contactErrors.custom_135 = 'Required'; // work address
      }
    }

    if (showPropertyWeHo) {
      if (!contact.custom_138) { // property WeHo
        contactErrors.custom_138 = 'Required';
      } else if (contact.custom_138==="1") {
        if (!contact.custom_160)
          contactErrors.custom_160 = 'Required'; // property address
        if (!contact.custom_163)
          contactErrors.custom_163 = 'Required'; // property zip
      }
    }

    if (showSchoolWeHo) {
      if (!contact.custom_146) { // school WeHo
        contactErrors.custom_146 = 'Required';
      } else if (contact.custom_146==="1") {
        if (!contact.custom_147)
          contactErrors.custom_147 = 'Required'; // school name
      }
    }

    if (showHomelessWeHo) {
      if (!contact.custom_148) { // homeless WeHo
        contactErrors.custom_148 = 'Required';
      } else if (contact.custom_148==="1") {
        if (!contact.custom_150)
          contactErrors.custom_150 = 'Required'; // homeless address
        if (!contact.custom_153)
          contactErrors.custom_153 = 'Required'; // homeless zip
        if (!contact.custom_203)
          contactErrors.custom_203 = 'Required'; // homeless description
      }
    }

    /**
     * Aggrigate errors
     */
    const contactErrorsKyes = Object.keys(contactErrors);
    if (contactErrorsKyes.length>0) {
      handleMessage('Please fill out all required information.', 'error');
      throw new SubmissionError({
        contact: contactErrors,
      });
      // throw new SubmissionError({
      //   _error: errorKeys.map(key => errors[key]).join(', '),
      // });
    }

    
    /**
     * Add in updated times
     */

    const now = moment().tz('America/Los_Angeles');
    const format = 'YYYY-MM-DD HH:mm:ss';

    if (showLivesWeHo)
      contact.custom_1364 = now.format(format);

    if (showWorksWeHo)
      contact.custom_1365 = now.format(format);

    if (showPropertyWeHo)
      contact.custom_1366 = now.format(format);
    
    if (showSchoolWeHo)
      contact.custom_1368 = now.format(format);
    
    if (showHomelessWeHo)
      contact.custom_1367 = now.format(format);

    /**
     * Save
     */

    const formData = new FormData();
    formData.append('json', JSON.stringify(contact));
  
    const url = handleCiviURLBuild('Contact', 'create');

    return fetch(url, {
      method: "POST",
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
      body: formData,
    })
    .then(response => response.json())
    .then(json => {
      // console.log(json);
      if (json.is_error) {
       throw new Error(json.error_message);
      }
      handleMessage('Account information saved', 'success');
      // reset what we are showing
      this.setState(initialState);
      handleUpdateCurrentState(''); // refresh this page
      return json;
    })
    .catch(error => {
      handleMessage(`There was an issue saving your account information (${error.message})`, 'error');
      return false;
    });

  }

  render() {

    const { open, toggle } = this.props;

    if (!open)
      return null;

    const { somethingMissing } = this.state;

    const { handleSubmit, pristine, submitting } = this.props;

    const {
      showLivesWeHo,
      showWorksWeHo,
      showPropertyWeHo,
      showSchoolWeHo,
      showHomelessWeHo,
    } = this.state;

    return (
      <Layer 
        className="DataEnrichment DataEnrichmentWeHo"
        full={true}
        margin="large"
        model={true}
        onClickOutside={() => toggle(false)}
        onEsc={() => toggle(false)}
        plain={false}
        responsive={true}
      >
        <Box fill>
          <Box
            direction='row'
            align='center'
            justify='between'
            background="light-2"
            pad={{ left: 'medium', right: 'small', vertical: 'small' }}
            elevation='medium'
            style={{ zIndex: '1' }}
          >
            <Box flex>
              <Heading level="2" style={{ margin: 0 }}>
                We need to know a little more about you
              </Heading>
            </Box>
            <Box>
              <Button icon={<Close />} label="Close" onClick={() => toggle(false)} />
            </Box>
          </Box>
          <Box fill style={{ padding: '24px', overflowY: 'auto' }}>
            <Form onSubmit={handleSubmit(this.submitForm)}>
              <p><em>
                We work with many funding sources to provide free and low cost 
                services including the City of West Hollywood. As part of our 
                agreement with the City, we must verify eligibility for funding.
              </em></p>
              <WeHo
                {...this.props}
                show={{
                  lives: showLivesWeHo===true,
                  works: showWorksWeHo===true,
                  property: showPropertyWeHo===true,
                  school: showSchoolWeHo===true,
                  homeless: showHomelessWeHo===true,
                }}
              />
              <div className="submit-buttons">
                <Button
                  icon={<Checkmark />}
                  label="Update Account"
                  type="submit"
                  primary={true}
                  disabled={(somethingMissing && pristine) || submitting}
                />
              </div>
            </Form>
          </Box>
        </Box>
      </Layer>
    );
  }
}

const singleName = 'dataenrichment_weho';
const formName = 'dataenrichment_weho';

const mapStateToProps = (state) => {

  const { auth: { contactId }, form } = state;
  
  const single = state.single[singleName];

  const formLoaded = formName in form && 'values' in form[formName];
  const formData = formLoaded && form[formName].values;

  return {
    contactId,
    ...single,
    formLoaded,
    formData,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    handleMessage: (message, variant=null, undo=null) => dispatch(addMessage(message, variant, undo)),

    handleUpdateLoadingState: state => dispatch(updateLoadingState(singleName, state)),
    handleUpdateCurrentState: state => dispatch(updateCurrentState(singleName, state)),
    handleSetSingleData: data => dispatch(setSingleData(singleName, data)),

    initializeDataEnrichment: data => dispatch(initialize(formName, data)),
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: formName,
  })(DataEnrichmentWeHo)
);