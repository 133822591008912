import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Box, Heading } from 'grommet';
import { Next, Previous } from 'grommet-icons';
import EventDisplay from './DropInEvents/EventDisplay';

class DropInEvents extends Component {

  constructor(props) {
    super(props);

    this.state = {
      page: 0,
    };
  }

  render() {

    const { page } = this.state;
    const perPage = 2;

    const { data, kioskLocation } = this.props;

    /**
     * dropin events where they're not already shown in our events
     */

    const dropins = data === null ? [] :
      data
      .filter(event => ['dropin', 'registereddropin'].includes(event.checkinStatus) && 
        [event.custom_1198, null].includes(kioskLocation))
      .map(event =>  
        event['api.Participant.get'].values.length>0 ?
        event['api.Participant.get'].values.filter(p => !['registered', 'pendingattended', 'hide'].includes(p.checkinStatus)) :
        event
      )
      .flat();

    // console.log({dropins, page, perPage}, (page+1)*perPage<dropins.length);

    return (
      <Box className="DropInEvents">
        <Box background="brand" flex="grow" pad={{ left: 'medium', right: 'small', vertical: 'small' }}>
          <Heading level="3" style={{ margin: 0 }}>
            Drop-in Events
          </Heading>
        </Box>
        <Box className="content" fill>
          <Box direction="row" align="center">
            <Box className="navBox" pad={{ vertical: 'small' }}>
              {page>0 && <Previous className="nav" size="xlarge" onClick={() => this.setState({ page: page-1 })} />}
            </Box>
            <Box pad={{ vertical: "small" }} flex>
              {dropins.length>0 ?
                <Box direction="row-responsive">
                  {dropins.slice(page*perPage, (page+1)*perPage).map(event => {
                    return (
                      <Box key={event.id} margin="small" flex>
                        <EventDisplay event={event} />
                      </Box>
                    )
                  })}
                </Box>
              :
                <Box pad={{ vertical: "small" }} flex><Heading level="3">No Drop-in Events</Heading></Box>
              }
            </Box>
            <Box className="navBox" pad={{ vertical: 'small' }}>
              {(page+1)*perPage<dropins.length && <Next className="nav" size="xlarge" onClick={() => this.setState({ page: page+1 })} />}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

const listName = 'participations';

const mapStateToProps = (state) => {
  const { kioskLocation } = state.load;
  return {
    ...state.list[listName],
    kioskLocation,
  };
}

const mapDispatchToProps = dispatch => {
  return {
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(DropInEvents);