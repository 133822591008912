/**
 * Used on Case Conference activity type 90
 */

const caseManagementServices = [
  // {  // verify with Kiera that this option should no longer be available for case management
  //   label: 'Housing/Assisted Living',
  //   value: '1',
  // },
  {
    label: 'Employment Assistance',
    value: '2',
  },
  {
    label: 'Legal Assistance',
    value: '3',
  },
  {
    label: 'Health/Mental Health',
    value: '4',
  },
  {
    label: 'Food Insecurity',
    value: '5',
  },
  {
    label: 'Benefits Assistance',
    value: '6',
  },
  {
    label: 'Veterans Services',
    value: '7',
  },
  {
    label: 'Social Support/Bereavement',
    value: '8',
  },
  // {
  //   label: 'Client Admin (Voicemail, Email, Mail, Case Consult)',
  //   value: '9',
  // },
  {
    label: 'Other',
    value: '10',
  },
];

export default caseManagementServices;