const veteranServices = [
  {
    label: 'Air Force',
    value: '1',
  },
  {
    label: 'Army',
    value: '2',
  },
  {
    label: 'Coast Guard',
    value: '3',
  },
  {
    label: 'Marine',
    value: '4',
  },
  {
    label: 'Navy',
    value: '5',
  },
];
export default veteranServices;

// SELECT
// GROUP_CONCAT(
//     CONCAT(
//         "  {\n",
//             "    label: '", label, "',\n"
//             "    value: '", `value`, "',\n"
//         '  },'
//     )
//     SEPARATOR
//     "\n"
// )
// FROM `civicrm_option_value`
// WHERE option_group_id=270