import React from 'react';
import { Image } from 'grommet';

import all_done from 'assets/images/help2/all_done.png';
import case_management from 'assets/images/help2/case_management.png';
import check from 'assets/images/help2/check.png';
import check_availability from 'assets/images/help2/check_availability.png';
import check_in_events from 'assets/images/help2/check_in_events.png';
import drop_in_events from 'assets/images/help2/drop_in_events.png';
import info_popup from 'assets/images/help2/info_popup.png';
import other_events from 'assets/images/help2/other_events.png';
import scan_id_card from 'assets/images/help2/scan_id_card_picture.png';
import schedule from 'assets/images/help2/schedule.png';
import show_my_schedule from 'assets/images/help2/show_my_schedule.png';
import username_password from 'assets/images/help2/username_password.png';

const Help = props => (
  <div className="Help">
    <p><strong>Q: How do I login to the Kiosk?</strong></p>
    <p>
      <strong><em>A: </em></strong>
      There are two ways to login to the Kiosk.
    </p>
    <ol>
      <li>
        Scan or swipe the QR Code that's on your key tag on the webcam.
        <br />
        <Image src={scan_id_card} margin="small" />
      </li>
      <li>
        Tap the space under Username and Password. An on-screen keyboard will appear on the bottom right corner of 
        the screen. Type in your Username and Password that you've signed up with through the Portal and then click 
        the Login button.
        <br />
        <Image src={username_password} margin="small" />
      </li>
    </ol>
    <p><strong>Q: What if I lose my key tag or forget my login information or both?</strong></p>
    <p>
      <strong><em>A: </em></strong>
      Please contact a Senior Services staff member for a temporary replacement key tag or to provide you with your 
      username and password.
    </p>
    <p><strong>Q: I'm new to the Center. How can I check-in for an event?</strong></p>
    <p>
      <strong><em>A: </em></strong>
      Please see a Senior Services staff member at the desk to request further information on how to be a registered 
      client of the Center.
    </p>
    <p><strong>Q: How do I check-in to my registered event/s?</strong></p>
    <p>
      <strong><em>A: </em></strong>
      Once you login to the Kiosk, you will see a box on the top-left side of the screen below your photograph and 
      name that says 'Check In To Your Registered Events.' This box will show all the events that you've RSVPed to join. 
      You can tap on square that's to the left of the event name to show more information about the event, including 
      event location and time. A check box like this <Image src={check} /> will appear to indicate that you are checked 
      into an event. You can check-in to as many events as you wish. Please tap on the &lt; or &gt; as shown below to 
      see all the events that for which you've registered.
      <br />
      <Image src={check_in_events} margin="small" />
    </p>
    <p><strong>Q: Can I attend 'Drop-In' events without RSVPing first? If yes, how can I check-in to 'Drop-In' events on the Kiosk?</strong></p>
    <p>
      <strong><em>A: </em></strong>
      You don't need to RSVP for 'Drop-In' events. You may want to check-in for Drop-In events in order to keep you 
      Kiosk record up-to-date. You can attend such events by checking-in on the Kiosk. Tap the on the square box to 
      the left of each Drop-In Event to check-in.
      <br />
      <Image src={drop_in_events} margin="small" />
    </p>
    <p><strong>Q: How can I schedule Case Management or Housing Navigation for the day?</strong></p>
    <p>
      <strong><em>A: </em></strong>
      You can find the 'Case Management Walk-In Services' and 'Housing Navigation Walk-In Services' sections in the top right and bottom right corners of the Kiosk screen, respectively. From 
      there, you can check available time slots by tapping 'Check Availability' button on the Kiosk.
      <br />
      <Image src={check_availability} margin="small" />
    </p>
    <p>
    You can then choose a time slot and select the reasons for your request. If the reason for your Appointment 
    request is not pre-listed, use the box at the bottom of the Kiosk screen to type in your reason for needing the appointment.
      <br />
      <Image src={case_management} margin="small" />
    </p>
    <p><strong>Q: How can I check my other events that are not included both in the 'Check-In To Your Registered Events' or 'Drop-In Events' boxes?</strong></p>
    <p>
      <strong><em>A: </em></strong>
      Once you login to the Kiosk, on the bottom right corner, you will see the 'Other Events Today' box. From here, you can 
      see all the events, including those that you are waitlisted for, that require an RSVP still, or that require you to 
      contact a Senior Services staff member before attending.
      <br />
      <Image src={other_events} margin="small" />
    </p>
    <p>
      When tapping on the event, a pop-up window, as shown below, will appear to notify you if you need to register for the 
      event or any other action that's needed.
      <br />
      <Image src={info_popup} margin="small" />
    </p>
    <p><strong>Q: Is there a way to see my schedule for the day?</strong></p>
    <p>
      <strong><em>A: </em></strong>
      Tap on 'Show My Schedule' button (in the middle of the lower part of the Kiosk screen), to display your schedule for the day.
      <br />
      <Image src={show_my_schedule} margin="small" />
    </p>
    <p>
      It will also show you other events and whether or not you've checked-in. Tap on 'OK' to go back to the main Kiosk screen.
      <br />
      <Image src={schedule} margin="small" />
    </p>
    <p><strong>Q: How do I log out of the Kiosk?</strong></p>
    <p>
      Tap the 'I'm Done' button on the top right corner of the Kiosk screen—as shown below—to log out.
      <br />
      <Image src={all_done} margin="small" />
    </p>
  </div>
);

export default Help;