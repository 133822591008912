import initialState from "../initialState";
import constants from "../constants";

// Reducers: basically translates the 'actions' objects.
// Naming them reducers is not recommended with the user of combineReducers.
// It's better to name them based on the state slice they are at to represent their data.
const loadReducer = (state = initialState.load, action) => {
    // console.log("Running action:", action);
    const newState = {...state};
    
    switch(action.type)
    {
        case constants.STATUS_MAP:
            newState.statusMap = action.value;
            newState.setup = true;
            return newState;
        case constants.KIOSK_LOCATION:
            newState.kioskLocation = action.value;
            return newState;
        default:
            return newState;
    }
}

export default loadReducer;