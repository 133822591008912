import constants from '../constants';
import store from '../'
import * as moment from 'moment-timezone';

export const updateCurrentPage = (list, currentPage) => ({
    type: constants.LIST_CURRENT_PAGE,
    value: {list, currentPage},
})

export const updatePageSize = (list, pageSize) => {

    const state = store.getState();

    const totalPages = Math.ceil(state.list[list].count/pageSize);
    const currentPage = Math.min(state.list[list].currentPage, totalPages - 1);

    return {
        type: constants.LIST_PAGE_SIZE,
        value: {list, pageSize, currentPage},
    };
}

export const updateSorting = (list, sortBy) => ({
    type: constants.LIST_SORTING,
    value: {list, sortBy},
})

export const updateSearchValue = (list, searchValue) => ({
    type: constants.LIST_SEARCH_VALUE,
    value: {list, searchValue},
})

export const updateDateRange = (list, startDate, endDate, date=null) => {

    const startDateMoment = moment(startDate);
    const endDateMoment = moment(endDate);

    return {
        type: constants.LIST_DATES,
        value: {
            list, 
            startDate: startDateMoment.toISOString(),
            endDate: endDateMoment.toISOString(),
            date: date===null ? null : moment(date).toISOString(),
        },
    };
}

export const updateCurrentListState = (list, tableState) => ({
    type: constants.LIST_TABLE_STATE, 
    value: {list, tableState},
})

export const updateLoadingListState = (list, loadingTableState) => ({
    type: constants.LIST_LOADING_TABLE_STATE, 
    value: {list, loadingTableState},
})

export const setListData = (list, response) => ({
    type: constants.LIST_DATA,
    value: {list, response},
})

export const updateListData = (list, updates, compartor, merge=false) => ({
    type: constants.LIST_DATA_UPDATE,
    value: {list, updates, compartor, merge},
})