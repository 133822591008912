import React, { Component } from 'react';
import { connect } from 'react-redux';
import { login } from 'store/actions/auth';
import { Field, Form, initialize, reduxForm } from 'redux-form';
import { Button } from 'grommet';
import { Clear, Login } from 'grommet-icons';
import { renderFormField } from 'helpers/FormFields';

// import KeyboardInput from 'views/App/KeyboardInput';

class UsernamePassword extends Component {

  constructor(props){
    super(props);
    this.state = {
      // keyboardValue: '',
    };
  }

  render() {

    // console.log(this.props)
    const { dirty, handleSubmit, initialize } = this.props;
    // const { keyboardValue } = this.state;

    return (
      <Form 
        className="UsernamePassword"
        onSubmit={handleSubmit}
      >
          <Field
            label="Username"
            name="username"
            component={renderFormField}
          />
          <Field
            label="Password"
            name="password"
            type="password"
            component={renderFormField}
          />
          <Button
            icon={<Clear />}
            label="Clear"
            color="dark-2"
            margin="small"
            disabled={!dirty}
            onClick={() => initialize({})}
          />
          <Button
            icon={<Login />}
            margin="small"
            label="Login"
            type="submit"
            primary={true}
          />
          {/* <KeyboardInput value={keyboardValue} onChange={val => {
            console.log(val)
            this.setState({keyboardValue: val})
          }} /> */}
      </Form>
    );
  }
}

const formName = 'login';

const validate = vals => {
  const errors = {};

  ['username', 'password'].forEach(fieldName => {
    if (!(fieldName in vals) || fieldName.length<1) {
      errors[fieldName] = 'Required';
    }
  });
 
  return errors;
}

const mapStateToProps = (state) => {
  return {
  };
}

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: async data => {
      const { username, password } = data;
      const loggedIn =  await dispatch(login(username, password));
      let newData = {};
      if (loggedIn===false) {
        newData.username = username;
      }
      dispatch(initialize([formName], newData));
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: formName,
    initialValues: {},
    validate,
  })(UsernamePassword)
);