import React from 'react';

const CommunicationAgreements = props => (
  <div className="CommunicationAgreements">
    <i>Senior Services strives to create a safe, welcoming, and respectful community where lesbian, gay, bisexual, transgender and queer people in their 50’s, 60’s, 70’s, 80’s 90’s and beyond can come together to learn connect, build friendships, gain support and thrive.</i> 
    <p><b>Everyone is Welcome:</b> Senior Services centers our work on the experience of LGBTQ identified people.  Allies are welcome, but we ask that allies participate thoughtfully and respectfully while you are in our space.  Certain groups and activities are intended for certain audiences.  We ask that you review our newsletter to determine if you meet group/event requirements and speak to staff if you have a question. Some groups may have additional agreements than those listed based on participant feedback.  </p>
    <p><b>Be On Time:</b> Please arrive no more than 15 minutes late to avoid disrupting others.  </p>
    <p><b>Respect Confidentiality:</b>   Stories and comments shared should remain private.  What is said in the room stays in the room.  Exceptions for this policy include if participants discuss harm to self, harm to others or elder abuse or neglect.  </p>
    <p><b>Make Space, Take Space, Step Back:</b>  This is your space and we want to hear from you but please only one person speaking at a time.  No interrupting others and be mindful of how much space you are taking and how that impacts others since there is only so much time together.  After 5 minutes of speaking staff may ask you to wrap up to give others a chance to speak.  We especially want to make sure new people or those we have not heard from get a chance to speak.  Silence your cell phone.  </p>
    <p><b>Only Use “I” Statements:</b> Speak from your own experiences.  Do not make assumptions or generalizations of other people’s experiences.  All experiences are unique and valid.  </p>
    <p><b>Questions:</b>  Feel free to ask group questions of everyone or to a facilitator but avoid asking an individual person a question, it can make people feel uncomfortable and singled out.</p>
    <p><b>Listen and Consider Before Responding:</b> It’s important to allow space for everyone’s perspective just as they are.  Sometimes it can feel personal when we hear something when that wasn’t what was intended.  Ask yourself about biases you may be carrying with you that could be shaping the way you are responding to others.  </p>
    <p><b>Silence Is Ok:</b> Allow everyone to share if and when they are ready.  </p>
    <p><b>Be Mindful of What You Bring Into the Space and How You Do It:</b>  Words matter.  Be thoughtful of tone and how your words may affect others in the group.  Some topics can be heavy, triggering or difficult for others.  Some content that includes trauma may be better shared with a trusted mental health clinician.  Connect with staff if you need a referral.  </p>
    <p><b>Personal Relationships May Impact Groups:</b> The Senior Center is sex positive and supports a healthy sexual life in all its forms for our participants.  With that, participants should be mindful and careful of how sexual interactions between group members outside of group may impact group dynamics.  Participants should avoid bringing private interpersonal interaction into group spaces.</p>
    <p><b>Be Mindful of Pronouns and Gendered Language:</b> Our community is VERY gender expansive, and we are making space and celebrating all identities.  If you don’t know a person’s pronouns-Ask.  Don’t deliberately or persistently misgender someone. If you misgender someone by mistake apologize.  We are all learning.     </p>
    <p><b>Substance-Free Space:</b> For the safety and comfort of others we ask that you not participate if you are under the influence of alcohol or substances.  </p>
    <p><b>Speak Up For Your Safety or Concerns:</b>  If something said is triggering or problematic, we need you to let us know. Everyone signed an agreement to be “thoughtful and courteous in your communication with participants, facilitators, volunteers and staff. The use of sexually explicit language, obscene gestures, or racial, religious, class, ability, age, sexual orientation, ethnic or gender-related slurs and bullying of any kind will not be tolerated under any circumstances.”  You can say something to a staff person, by email or by completing our “Participant feedback form”.  We want everyone to feel welcome at all times.  </p>
  </div>
);

export default CommunicationAgreements;