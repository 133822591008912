import React from 'react';
import preval from 'preval.macro';

const About = props => (
  <div className="About">
    <dl>
      <dt>Build Date</dt>
      <dd>
        {preval`
          const moment = require('moment-timezone');
          const now = moment().tz("America/Los_Angeles");
          const format = 'YYYY-MM-DD HH:mm:ss';
          module.exports = now.format(format);
        `}
      </dd>
      <dt>Cookie Status</dt>
      <dd>Sufficient</dd>
      <dt>Happieess Level</dt>
      <dd>High</dd>
      <dt>Current Plan</dt>
      <dd>Smile</dd>
    </dl>
  </div>
);

export default About;