import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { Box } from 'grommet';
import { renderFormField, renderSelectField } from 'helpers/FormFields';
import booleanList from 'helpers/Filter/boolean';

class WeHo extends Component {
  
  render() {

    const { 
      formData,
      formLoaded,
      show,
    } = this.props;

    const showLives = !show || show.lives;
    const showWorks = !show || show.works;
    const showProperty = !show || show.property;
    const showSchool = !show || show.school;
    const showHomeless = !show || show.homeless;

    return (
      <div className="WeHo">
        <Box direction="row-responsive" wrap={true}>
          {showLives ?
            <Box className="live-weho" basis="1/2" pad="small">
              <Field
                label="Do you live in West Hollywood? *"
                id="custom_129" 
                name="contact[custom_129]" 
                // disabled={isFetching}
                component={renderSelectField}
                options={booleanList}
              />
              {formLoaded && formData.contact.custom_129==="1" ?
                <>
                  <Field
                    label="Street Address *"
                    name="contact[custom_286]" 
                    // disabled={isFetching}
                    component={renderFormField}
                  />
                  <Field
                    label="Zip *"
                    name="contact[custom_289]" 
                    // disabled={isFetching}
                    component={renderFormField}
                  />
                  <hr />
                </>
              : null} 
            </Box>
          : null}
          {showWorks ?
            <Box className="work-weho" basis="1/2" pad="small">
              <Field
                label="Do you work in West Hollywood? *"
                id="custom_130" 
                name="contact[custom_130]" 
                // disabled={isFetching}
                component={renderSelectField}
                options={booleanList}
              />
              {formLoaded && formData.contact.custom_130==="1" ?
                <>
                  <Field
                    label="Employer *"
                    name="contact[custom_202]" 
                    // disabled={isFetching}
                    component={renderFormField}
                  />
                  <Field
                    label="Street Address *"
                    name="contact[custom_132]" 
                    // disabled={isFetching}
                    component={renderFormField}
                  />
                  <Field
                    label="Zip *"
                    name="contact[custom_135]" 
                    // disabled={isFetching}
                    component={renderFormField}
                  />
                  <hr />
                </>
              : null}
            </Box>
          : null}
          {showProperty ?
            <Box className="property-weho" basis="1/2" pad="small">
              <Field
                label="Do you own propery in West Hollywood? *"
                id="custom_138" 
                name="contact[custom_138]" 
                // disabled={isFetching}
                component={renderSelectField}
                options={booleanList}
              />
              {formLoaded && formData.contact.custom_138==="1" ?
                <>
                  <Field
                    label="Street Address *"
                    name="contact[custom_160]" 
                    // disabled={isFetching}
                    component={renderFormField}
                  />
                  <Field
                    label="Zip *"
                    name="contact[custom_163]" 
                    // disabled={isFetching}
                    component={renderFormField}
                  />
                  <hr />
                </>
              : null}
            </Box>
          : null}
          {showSchool ?
            <Box className="school-weho" basis="1/2" pad="small">
              <Field
                label="Do you attend school in West Hollywood? *"
                id="custom_146" 
                name="contact[custom_146]" 
                // disabled={isFetching}
                component={renderSelectField}
                options={booleanList}
              />
              {formLoaded && formData.contact.custom_146==="1" ?
                <>
                  <Field
                    label="Which School? *"
                    name="contact[custom_147]" 
                    // disabled={isFetching}
                    component={renderFormField}
                  />
                  <hr />
                </>
              : null}
            </Box>
          : null}
          {showHomeless ?
            <Box className="homeless-weho" basis="1/2" pad="small">
              <Field
                label="Do you spend time homeless in West Hollywood? *"
                id="custom_148" 
                name="contact[custom_148]" 
                // disabled={isFetching}
                component={renderSelectField}
                options={booleanList}
              />
              {formLoaded && formData.contact.custom_148==="1" ?
                <>
                  <Field
                    label="Street Address *"
                    name="contact[custom_150]" 
                    // disabled={isFetching}
                    component={renderFormField}
                  />
                  <Field
                    label="Zip *"
                    name="contact[custom_153]" 
                    // disabled={isFetching}
                    component={renderFormField}
                  />
                  <Field
                    label="Description *"
                    name="contact[custom_203]" 
                    // disabled={isFetching}
                    component={renderFormField}
                  />
                  <hr />
                </>
              : null}
            </Box>
          : null}
        </Box>
      </div>
    );
  }
}

const singleName = 'account';

const mapStateToProps = (state) => {

  const { auth: { siteURL, testMode }, single } = state;

  return {
    ...single[singleName],

    siteURL,
    testMode,
  };
}

const mapDispatchToProps = dispatch => {
  return {};
}
  
export default connect(mapStateToProps, mapDispatchToProps)(WeHo);