import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { Box } from 'grommet';
import { renderFormField, renderSelectField, renderCheckBox } from 'helpers/FormFields';

import PhotoPermissionDialog from './Dialogs/PhotoPermissionDialog';
import LiabilityReleaseDialog from './Dialogs/LiabilityReleaseDialog';
import CommunicationAgreementsDialog from './Dialogs/CommunicationAgreementsDialog';

import booleanList from 'helpers/Filter/boolean';
import gendersList from 'helpers/Filter/genders';
import hivStatusesList from 'helpers/Filter/hivStatuses';
import phoneTypesList from 'helpers/Filter/phoneTypes';
// import photoPermissionsList from 'helpers/Filter/photoPermissions';
import pronounsList from 'helpers/Filter/pronouns';
import racesList from 'helpers/Filter/races';
import sexualOrientationsList from 'helpers/Filter/sexualOrientations';
import veteranServicesList from 'helpers/Filter/veteranServices';

class Demographics extends Component {

  constructor(props){
    super(props);
    this.state = {
      photoPermissionDialog: false,
      liabilityReleaseOpen: false,
      communicationAgreementsOpen: false,
    };
  }

  togglePhotoPermissionOpen = (state=null) => this.setState({ photoPermissionDialog: state===null ? !this.state.photoPermissionDialog : state });
  toggleLiabilityReleaseOpen = (state=null) => this.setState({ liabilityReleaseOpen: state===null ? !this.state.liabilityReleaseOpen : state });
  toggleCommunicationAgreementsOpen = (state=null) => this.setState({ communicationAgreementsOpen: state===null ? !this.state.communicationAgreementsOpen : state });

  render() {

    const { 
      formData,
      formLoaded,
      show,
    } = this.props;

    const showEmail = !show || show.email;
    const showPhone = !show || show.phone;
    const showPronoun = !show || show.pronoun;
    const showDOB = !show || show.dob;
    const showVeteran = !show || show.veteran;
    const showRace = !show || show.race;
    const showDisabled = !show || show.disabled;
    const showHIV = !show || show.hiv;
    const showOrientation = !show || show.orientation;
    const showGender = !show || show.gender;
    const showTrans = !show || show.trans;
    const showHomeless = !show || show.homeless;
    const showHouseholdIncome = !show || show.householdIncome;
    const showHouseholdSize = !show || show.householdSize;
    const showPhotoPermissions = !show || show.photoPermission;
    const showLiabilityRelease = !show || show.liabilityRelease;
    const showCommunicationAgreements = !show || show.communicationAgreements;

    return (
      <div className="Demographics">
        <Box direction="row-responsive" wrap={true}>
          {showEmail ?
            <Box className="email-demographics" basis="1/2" pad="small">
              <Field
                label="Email"
                id="email" 
                name="contact[email]" 
                component={renderFormField}
                type="email"
              />
            </Box>
          : null}
          {showPhone ?
            <Box className="phone-demographics" basis="1/2" pad="small">
              <Field
                label="Phone"
                id="phone" 
                name="contact[phone]" 
                component={renderFormField}
                type="tel"
              />
              {formLoaded && formData.contact.phone && formData.contact.phone.length>0 ?
                <>
                  <Field
                    label="Phone Type"
                    id="phone_type" 
                    name="contact[phone_type_id]" 
                    // disabled={isFetching}
                    component={renderSelectField}
                    options={phoneTypesList}
                  />
                </>
              : null}
            </Box>
          : null}
          {showDOB ?
            <Box className="dob-demographics" basis="1/2" pad="small">
              <Field
                label="Date of Birth *"
                id="birth_date" 
                name="contact[birth_date]" 
                component={renderFormField}
                type="date"
              />
            </Box>
          : null}
          {showDisabled ?
            <Box className="disabled-demographics" basis="1/2" pad="small">
              <Field
                label="Are you disabled? *"
                id="custom_1213" 
                name="contact[custom_1213]" 
                component={renderSelectField}
                options={booleanList}
              />
            </Box>
          : null}
          {showGender ?
            <Box className="gender-demographics" basis="1/2" pad="small">
              <Field
                label="Gender Identity *"
                id="gender_id" 
                name="contact[gender_id]" 
                component={renderSelectField}
                options={gendersList}
              />
            </Box>
          : null}
          {showTrans ?
            <Box className="transgender-demographics" basis="1/2" pad="small">
              <Field
                label="Are you transgender? *"
                id="custom_1215" 
                name="contact[custom_1215]" 
                component={renderSelectField}
                options={booleanList}
              />
            </Box>
          : null}
          {showOrientation ?
            <Box className="orientation-demographics" basis="1/2" pad="small">
              <Field
                label="Sexual Orientation *"
                id="custom_113" 
                name="contact[custom_113]" 
                component={renderSelectField}
                options={sexualOrientationsList}
              />
            </Box>
          : null}
          {showPronoun ?
            <Box className="pronoun-demographics" basis="1/2" pad="small">
              <Field
                label="Preferred pronouns *"
                id="custom_280" 
                name="contact[custom_280]" 
                component={renderSelectField}
                options={pronounsList}
              />
            </Box>
          : null}
          {showRace ?
            <Box className="race-demographics" basis="1/2" pad="small">
              <Field
                label="Race *"
                id="custom_1212" 
                name="contact[custom_1212]" 
                component={renderSelectField}
                // multiple={true}
                options={racesList}
              />
            </Box>
          : null}
          {showVeteran ?
            <Box className="veteran-demographics" basis="1/2" pad="small">
              <Field
                label="Are you a veteran? *"
                id="custom_115" 
                name="contact[custom_115]" 
                component={renderSelectField}
                options={booleanList}
              />
              {formLoaded && formData.contact.custom_115==="1" ?
                <>
                  <Field
                    label="What branch? *"
                    id="custom_447" 
                    name="contact[custom_447]" 
                    // disabled={isFetching}
                    component={renderSelectField}
                    options={veteranServicesList}
                  />
                </>
              : null}
            </Box>
          : null}
          {showHomeless ?
            <Box className="homeless-demographics" basis="1/2" pad="small">
              <Field
                label="Are you currently homeless? *"
                id="custom_1214" 
                name="contact[custom_1214]" 
                component={renderSelectField}
                options={booleanList}
              />
            </Box>
          : null}
          {showHouseholdIncome ?
            <Box className="householdincome-demographics" basis="1/2" pad="small">
              <Field
                label="What is the annual income for your household? *"
                id="custom_1217" 
                name="contact[custom_1217]" 
                component={renderFormField}
                type="number"
              />
            </Box>
          : null}
          {showHouseholdSize ?
            <Box className="householdsize-demographics" basis="1/2" pad="small">
              <Field
                label="How many people live in your household including yourself? *"
                id="custom_1216" 
                name="contact[custom_1216]" 
                component={renderFormField}
                type="number"
              />
            </Box>
          : null}
          {showHIV ?
            <Box className="hiv-demographics" basis="1/2" pad="small">
              <Field
                label="HIV Status *"
                id="custom_234" 
                name="contact[custom_234]" 
                component={renderSelectField}
                options={hivStatusesList}
              />
            </Box>
          : null}
          {showPhotoPermissions ?
            <Box className="photopermission-demographics" basis="1/2" pad="small">
              <Box direction="row">
                <Box pad="none" flex={false}>
                  <Field
                    // label="I agree to the Photo Permission *"
                    id="custom_1225" 
                    name="contact[custom_1225]" 
                    component={renderCheckBox}
                  />
                </Box>
                <Box pad="none" flex="grow" justify="center">
                  <div>I agree to the <span className="fake-link" onClick={() => this.togglePhotoPermissionOpen(true)}>Photo Permission</span> *</div>
                </Box>
              </Box>
              {/* <Field
                label="I agree to the Photo Permission *"
                id="custom_1225" 
                name="contact[custom_1225]" 
                component={renderSelectField}
                options={photoPermissionsList}
              />
              <div><span className="fake-link" onClick={() => this.togglePhotoPermissionOpen(true)}>Photo Permission</span></div> */}
            </Box>
          : null}
          {showLiabilityRelease ?
            <Box className="liabilityrelease-demographics" basis="1/2" pad="small">
              <Box direction="row">
                <Box pad="none" flex={false}>
                  <Field
                    // label="I agree to the Release of Liability and Participation *"
                    id="custom_1306" 
                    name="contact[custom_1306]" 
                    component={renderCheckBox}
                  />
                </Box>
                <Box pad="none" flex="grow" justify="center">
                  <div>I agree to the <span className="fake-link" onClick={() => this.toggleLiabilityReleaseOpen(true)}>Release of Liability and Participation</span> *</div>
                </Box>
              </Box>
              {/* <Field
                label="I agree to the Release of Liability and Participation *"
                id="custom_1306" 
                name="contact[custom_1306]" 
                component={renderSelectField}
                options={booleanList}
              />
              <div><span className="fake-link" onClick={() => this.toggleLiabilityReleaseOpen(true)}>View Release of Liability and Participation</span></div> */}
            </Box>
          : null}
          {showCommunicationAgreements ?
            <Box className="communicationagreements-demographics" basis="1/2" pad="small">
              <Box direction="row">
                <Box pad="none" flex={false}>
                  <Field
                    // label="I agree to the Communication Agreements *"
                    id="custom_1474" 
                    name="contact[custom_1474]" 
                    component={renderCheckBox}
                  />
                </Box>
                <Box pad="none" flex="grow" justify="center">
                  <div>I agree to the <span className="fake-link" onClick={() => this.toggleCommunicationAgreementsOpen(true)}>Communication Agreements</span> *</div>
                </Box>
              </Box>
              {/* <Field
                label="I agree to the Communication Agreements *"
                id="custom_1474" 
                name="contact[custom_1474]" 
                component={renderSelectField}
                options={booleanList}
              />
              <div><span className="fake-link" onClick={() => this.toggleCommunicationAgreementsOpen(true)}>View Communication Agreements</span></div> */}
            </Box>
          : null}
        </Box>
        <PhotoPermissionDialog open={this.state.photoPermissionDialog} toggle={this.togglePhotoPermissionOpen} />
        <LiabilityReleaseDialog open={this.state.liabilityReleaseOpen} toggle={this.toggleLiabilityReleaseOpen} />
        <CommunicationAgreementsDialog open={this.state.communicationAgreementsOpen} toggle={this.toggleCommunicationAgreementsOpen} />
      </div>
    );
  }
}

const singleName = 'account';

const mapStateToProps = (state) => {

  const { auth: { siteURL, testMode }, single } = state;

  return {
    ...single[singleName],

    siteURL,
    testMode,
  };
}

const mapDispatchToProps = dispatch => {
  return {};
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Demographics);